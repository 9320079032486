/* ================================================== 
*	Project Name 	:  Appal
*	File 			:  Responsive CSS Base
*	Version 		:  1.0.0
*	Last change 	:  23 February 2019
*	Author 			:  Marvel Theme
*	Developer:		:  Rakibul Islam Dewan

*	css code for responsive layout To make Responsive
================================================== */

/* ==================================================
*	1 - media screen and (max-width: 1440px)
*	2 - media screen and (max-width: 1199px)
*	3 - media screen and (max-width: 991px)
*	4 - media screen and (max-width: 767px)
*	5 - media screen and (max-width: 680px)
*	6 - media screen and (max-width: 480px)
*	7 - media screen and (max-width: 320px)
================================================== */

/*  ====================================================================================================
1 - media screen and (max-width: 1440px) - start
==================================================================================================== */

@media screen and (max-width: 1440px) {

  .vrau {
    width: 100%;
    display: grid;
  }
  /* breadcrumb section - start
	================================================== */
  .breadcrumb-section .design-image-1 {
    right: 180px;
  }
  .breadcrumb-section .cross-image-1 {
    left: 15px;
  }
  .breadcrumb-section .cross-image-2 {
    left: 325px;
  }
  .breadcrumb-section .flow-image-1 {
    top: 150px;
    left: 200px;
  }
  .breadcrumb-section .box-image-1 {
    top: 220px;
    left: 460px;
  }
  .breadcrumb-section .flow-image-2 {
    top: 380px;
    left: 620px;
  }
  .breadcrumb-section .circle-half-1 {
    left: 660px;
  }
  .breadcrumb-section .circle-image-1 {
    right: 380px;
  }

  .breadcrumb-section .box-image-2 {
    right: 100px;
    bottom: 100px;
  }
  .breadcrumb-section .circle-half-2 {
    top: 270px;
    right: 80px;
  }
  /* breadcrumb section - end
	================================================== */

  /* home 1 pages - start
	================================================== */
  .banner-section .mobile-app-banner-1 .shape-1 {
    top: 340px !important;
    left: 15px !important;
  }
  .banner-section .mobile-app-banner-1 .shape-2 {
    top: 145px !important;
    left: 100px !important;
  }
  .banner-section .mobile-app-banner-1 .shape-3 {
    bottom: 30px;
    left: 170px !important;
  }
  .banner-section .mobile-app-banner-1 .shape-4 {
    top: 155px !important;
    left: 400px !important;
  }
  .banner-section .mobile-app-banner-1 .shape-5 {
    bottom: 60px;
    left: 650px !important;
  }
  .banner-section .mobile-app-banner-1 .shape-6 {
    bottom: 250px;
    left: 720px !important;
  }
  .banner-section .mobile-app-banner-1 .shape-7 {
    top: 150px !important;
    left: 780px !important;
  }
  .banner-section .mobile-app-banner-1 .banner-content {
    padding-top: 50px;
  }

  .banner-section .mobile-app-banner-1 {
    overflow: hidden;
  }
  .banner-section .mobile-app-banner-1 .banner-image .bg-image {
    width: 540px;
    margin-right: 0px;
  }
  .banner-section .mobile-app-banner-1 .banner-image .phone-image {
    right: 130px;
    width: 210px;
    top: 155px !important;
  }
  .banner-section .mobile-app-banner-1 .banner-image .man-image-1 {
    width: 190px;
    left: 0px !important;
  }
  .banner-section .mobile-app-banner-1 .banner-image .man-image-2 {
    width: 185px;
    right: -20px;
  }
  .banner-section .mobile-app-banner-1 .banner-image .man-image-3 {
    right: 60px;
    width: 135px;
    top: 0px !important;
  }
  .banner-section .mobile-app-banner-1 .banner-image .cog-image {
    top: 80px !important;
    left: 40% !important;
  }
  .banner-section .mobile-app-banner-1 .banner-image .leaf-image-2 {
    right: 0;
    width: 175px;
    top: 245px !important;
  }

  .features-section .feature-item .feature-image-1 .circle-image {
    width: 400px;
    margin: 0px auto;
  }
  .features-section .feature-item .feature-image-1 {
    margin-bottom: 60px;
  }
  .features-section .feature-item:nth-child(2) .feature-image-1 {
    margin-top: 60px;
    margin-bottom: 0px;
  }
  .features-section .feature-item:nth-child(2) .feature-image-1 .circle-image {
    margin: 0px auto;
  }
  .features-section .feature-item .feature-image-1 .phone-image {
    top: -25px;
    left: 25px;
    width: 350px;
  }
  .features-section .feature-item:nth-child(2) .feature-image-1 .phone-image {
    top: -29px;
    right: 95px;
  }
  .features-section .feature-item .feature-content {
    padding-top: 0px;
  }
  .features-section .feature-item .feature-image-1 .comment-image-1,
  .features-section .feature-item .feature-image-1 .comment-image-2 {
    width: 300px;
  }
  .features-section .feature-item .feature-image-1 .comment-image-1 {
    left: 75px;
    top: 115px;
  }
  .features-section .feature-item .feature-image-1 .comment-image-2 {
    left: 110px;
    bottom: -13px;
  }
  .features-section
    .feature-item:nth-child(2)
    .feature-image-1
    .comment-image-1,
  .features-section
    .feature-item:nth-child(2)
    .feature-image-1
    .comment-image-2 {
    width: 320px;
  }
  .features-section
    .feature-item:nth-child(2)
    .feature-image-1
    .comment-image-1 {
    top: 15px;
    left: -35px;
  }
  .features-section
    .feature-item:nth-child(2)
    .feature-image-1
    .comment-image-2 {
    bottom: 0px;
    right: 20px;
  }

  .service-section .container {
    max-width: 1200px;
  }

  .app-section .app-image .circle-image {
    width: 400px;
    margin: 0px auto;
  }
  .app-section .app-image .phone-image {
    top: 5px;
    right: 48px;
    width: 300px;
  }
  .app-section .app-content {
    padding-top: 78px;
  }

  .blog-section .blog-carousel:before {
    left: -50px;
  }
  .blog-section .blog-carousel:after {
    right: -50px;
  }
  /* home 1 pages - end
	================================================== */

  /* home 2 pages - start
	================================================== */
  .banner-section .mobile-app-banner-2 .shape-image-2 {
    left: 560px;
    bottom: 40px;
  }
  .banner-section .mobile-app-banner-2 .shape-image-3 {
    top: 202px;
    left: 600px;
  }
  .banner-section .mobile-app-banner-2 .shape-image-4 {
    top: 150px;
    right: 250px;
  }
  .banner-section .mobile-app-banner-2 .banner-image .man-image-2 {
    width: 175px;
    right: 30px !important;
  }
  .banner-section .mobile-app-banner-2 .banner-image .message-image {
    width: 120px;
    top: 240px !important;
  }
  .banner-section .mobile-app-banner-2 .banner-image .man-image-1 {
    width: 125px;
    left: 70px !important;
  }
  .banner-section .mobile-app-banner-2 {
    height: 850px;
    padding-top: 170px;
    margin-bottom: 0px;
  }
  .banner-section .mobile-app-banner-2 .banner-image .phone-image {
    z-index: 1;
    width: 300px;
  }
  .banner-section .mobile-app-banner-2 .banner-image .leaf-image-2 {
    width: 180px;
    right: 30px !important;
  }

  .features-section {
    overflow: hidden;
  }
  .features-section .feature-item .feature-image-2 .item-image {
    width: 570px;
    margin-left: 0px;
  }
  .features-section .feature-item .feature-image-2 .popup-video {
    right: -65px;
    bottom: 104px;
  }
  .features-section .feature-item .feature-image-2 .bg-image {
    width: 500px;
    margin-top: 115px;
    margin-right: 0px;
  }
  .features-section .feature-item .feature-image-2 .phone-image {
    right: 0px;
    width: 400px;
  }
  .features-section .feature-item .feature-image-2 .comment-image-1 {
    top: 145px;
    right: -80px;
  }
  .features-section .feature-item .feature-image-2 .comment-image-2 {
    right: -100px;
    bottom: 80px;
  }
  .features-section .feature-item .feature-image-2 .shape-image-1 {
    top: 15px;
    left: 70px;
  }
  .features-section .feature-item .feature-image-2 .shape-image-2 {
    top: 50%;
    right: -20px;
  }
  .features-section .feature-item .feature-image-2 .shape-image-3 {
    left: 90px;
    bottom: 10px;
  }
  .features-section .feature-item:nth-child(2) .feature-image-2 .bg-image {
    margin-left: 0px;
    margin-right: 0px;
  }
  .features-section .feature-item:nth-child(2) .feature-image-2 .phone-image {
    left: 60px;
    right: unset;
  }
  .features-section
    .feature-item:nth-child(2)
    .feature-image-2
    .comment-image-1 {
    top: 50px;
    left: 0;
    right: unset;
  }
  .features-section
    .feature-item:nth-child(2)
    .feature-image-2
    .comment-image-2 {
    left: 170px;
    right: unset;
  }
  .features-section .feature-item:nth-child(2) .feature-image-2 .shape-image-1 {
    left: 55px;
  }
  .features-section .feature-item:nth-child(2) .feature-image-2 .shape-image-2 {
    right: 0;
  }
  .features-section .feature-item:nth-child(2) .feature-image-2 .shape-image-3 {
    left: 60px;
    bottom: 0px;
  }
  .app-section .app-image-2 .bg-image {
    width: 500px;
    margin-right: 0px;
  }
  .app-section .app-image-2 .phone-image {
    top: 0;
    width: 400px;
  }
  .app-section {
    overflow: hidden;
  }
  .app-section .app-image-2 .phone-image {
    right: 30px;
    width: 400px;
  }
  /* home 2 pages - end
	================================================== */

  /* home 3 pages - start
	================================================== */
  .slider-section .mobileapp-main-carousel {
    width: 1300px;
    margin: 0 auto;
  }
  .slider-section .mobileapp-main-carousel .item.first-item {
    overflow: hidden;
    padding-top: 80px;
  }
  .slider-section .mobileapp-main-carousel .item {
    height: 790px;
    background-size: cover;
  }
  .slider-section .mobileapp-main-carousel .item.second-item {
    padding-top: 80px;
  }
  .slider-section
    .mobileapp-main-carousel
    .item.second-item
    .slider-image
    .phone-image {
    width: 380px;
  }
  .slider-section
    .mobileapp-main-carousel
    .item.second-item
    .slider-image
    .commentbar-image-1 {
    top: 50px !important;
    left: -120px !important;
  }
  .slider-section
    .mobileapp-main-carousel
    .item.second-item
    .slider-image
    .commentbar-image-2 {
    top: 355px !important;
    left: -105px !important;
  }
  .slider-section .mobileapp-main-carousel .item.second-item .slider-content {
    padding-top: 100px;
  }
  /* home 3 pages - end
	================================================== */

  /* home 4 pages - start
	================================================== */
  .banner-section .mobile-app-banner-4 {
    height: auto;
    padding-top: 200px;
  }
  .banner-section .mobile-app-banner-4 .banner-content {
    padding-top: 0px;
  }
  .banner-section .mobile-app-banner-4 .banner-image {
    padding-top: 100px;
    padding-bottom: 0px;
  }
  .banner-section .mobile-app-banner-4 .banner-image .phone-image {
    width: 590px;
    margin-right: 0px;
  }
  .banner-section .mobile-app-banner-4 .banner-image .man-image-1 {
    width: 220px;
    top: 10px !important;
    left: 40px !important;
  }
  .banner-section .mobile-app-banner-4 .banner-image .sms-image-1 {
    width: 150px;
    top: 105px !important;
    left: 155px !important;
  }
  .banner-section .mobile-app-banner-4 .banner-image .man-image-2 {
    width: 230px;
    top: 20px !important;
    right: -3px !important;
  }
  .banner-section .mobile-app-banner-4 .banner-image .sms-image-2 {
    width: 150px;
    top: -30px !important;
    right: 30px !important;
  }
  .banner-section .mobile-app-banner-4 .banner-image .tree-image {
    right: 170px;
    bottom: 0px;
    width: 150px;
  }
  .banner-section .mobile-app-banner-4 .bg-shape-image {
    top: 0px;
    right: 0px;
    width: 53%;
  }

  .features-section .feature-item .feature-image-4 {
    padding-bottom: 40px;
    float: none !important;
  }
  .features-section .feature-item .feature-image-4 .phone-image {
    width: 250px;
    display: table;
    margin: 0px auto;
    float: none !important;
  }
  .features-section .feature-item .feature-image-4 .floor-image {
    bottom: 2px;
    width: 480px;
    left: 0px;
  }
  .features-section .feature-item .feature-image-4 .man-image-1 {
    bottom: 40px;
    left: -12px;
    width: 150px;
  }
  .features-section .feature-item .feature-image-4 .flower-image-2 {
    width: 80px;
    bottom: 37px;
    right: -5px;
  }
  .features-section .feature-item .feature-image-4 .comment-1-image {
    top: 85px;
    left: 25px;
    width: 250px;
  }
  .features-section .feature-item .feature-image-4 .comment-2-image {
    top: 225px;
    left: 110px;
    width: 250px;
  }
  .features-section .feature-item .feature-image-4 .shape-image-1 {
    bottom: 42px;
    left: 60px;
    width: 50px;
  }
  .features-section .feature-item .feature-image-4 .shape-image-2 {
    top: 40%;
    right: 60px;
    width: 50px;
  }

  .features-section .feature-item:nth-child(2) .feature-image-4 .box-image-1 {
    top: 50px;
    left: 148px;
    width: 80px;
  }
  .features-section .feature-item:nth-child(2) .feature-image-4 .box-image-2 {
    top: 150px;
    right: 150px;
    width: 80px;
  }
  .features-section .feature-item:nth-child(2) .feature-image-4 .box-image-3 {
    top: 250px;
    left: 150px;
    width: 80px;
  }
  .features-section .feature-item:nth-child(2) .feature-image-4 .man-image-1 {
    bottom: 0px;
    right: 60px;
    width: 160px;
  }
  .features-section .feature-item:nth-child(2) .feature-image-4 .man-image-2 {
    bottom: 0px;
    left: -105px;
    width: 340px;
  }
  .features-section .feature-item:nth-child(2) .feature-image-4 .flower-image {
    width: 110px;
    right: -20px;
    bottom: 100px;
  }
  /* home 4 pages - end
	================================================== */

  /* home 5 pages - start
	================================================== */
  .banner-section .mobile-app-banner-5 .banner-content .title-text {
    font-size: 48px;
  }
  .banner-section .mobile-app-banner-5 {
    padding-top: 170px;
  }
  .banner-section .mobile-app-banner-5 .banner-content {
    padding-top: 20px;
  }
  .banner-section .mobile-app-banner-5 .banner-image .phone-image {
    width: 390px;
    display: table;
    margin: 0px auto;
    float: none !important;
  }
  .banner-section .mobile-app-banner-5 .banner-image .commentbar-image-1,
  .banner-section .mobile-app-banner-5 .banner-image .commentbar-image-2 {
    width: 350px;
  }
  .banner-section .mobile-app-banner-5 .banner-image .commentbar-image-1 {
    right: 160px;
    top: 73px !important;
  }
  .banner-section .mobile-app-banner-5 .banner-image .commentbar-image-2 {
    right: 140px;
    top: 390px !important;
  }
  /* home 5 pages - end
	================================================== */

  /* home 6 pages - start
	================================================== */
  .banner-section .mobile-app-banner-6 .banner-content {
    padding-top: 100px;
  }
  .banner-section .mobile-app-banner-6 .banner-image .phone-image {
    width: 400px;
    margin-right: -45px;
  }
  .banner-section .mobile-app-banner-6 .banner-image .commentbar-image-1,
  .banner-section .mobile-app-banner-6 .banner-image .commentbar-image-2 {
    width: 370px;
  }
  .banner-section .mobile-app-banner-6 .banner-image .commentbar-image-1 {
    top: 125px !important;
    left: -152px !important;
  }
  .banner-section .mobile-app-banner-6 .banner-image .commentbar-image-2 {
    top: 340px !important;
    left: -140px !important;
  }
  .banner-section .mobile-app-banner-6 .shape-bg-image {
    top: -305px;
    right: -525px;
    width: 1255px;
  }

  .features-section .feature-item .feature-image-6 {
    padding-top: 95px;
  }
  .features-section .feature-item .feature-image-6 .bg-image {
    width: 550px;
    margin-left: -30px;
  }
  .features-section .feature-item .feature-image-6 .phone-image {
    top: 0px;
    left: 75px;
    width: 400px;
  }
  .features-section .feature-item .feature-image-6 .comment-image-1 {
    top: 170px;
    left: 150px;
  }
  .features-section .feature-item .feature-image-6 .comment-image-2 {
    left: 190px;
    bottom: 90px;
  }

  .features-section .feature-item .feature-image-6 .shape-image-1 {
    top: 30px;
    left: 80px;
  }
  .features-section .feature-item .feature-image-6 .shape-image-2 {
    top: 52%;
    right: 0px;
  }
  .features-section .feature-item .feature-image-6 .shape-image-3 {
    left: 100px;
    bottom: 10px;
  }
  .features-section .feature-item .feature-image-6 .popup-video {
    top: 280px;
    right: -75px;
  }

  .features-section .feature-item:nth-child(2) .feature-image-6 .bg-image {
    margin-left: 0px;
    margin-right: -30px;
  }
  .features-section .feature-item:nth-child(2) .feature-image-6 .phone-image {
    right: 50px;
  }
  .features-section
    .feature-item:nth-child(2)
    .feature-image-6
    .comment-image-1 {
    top: 60px;
    left: -60px;
  }
  .features-section
    .feature-item:nth-child(2)
    .feature-image-6
    .comment-image-2 {
    left: 160px;
  }
  .features-section .feature-item:nth-child(2) .feature-image-6 .shape-image-1 {
    left: 70px;
  }
  .features-section .feature-item:nth-child(2) .feature-image-6 .shape-image-2 {
    right: 30px;
  }
  .features-section .feature-item:nth-child(2) .feature-image-6 .shape-image-3 {
    left: 80px;
  }
  /* home 6 pages - end
	================================================== */

  /* home 7 pages - start
	================================================== */
  .features-section .feature-item .feature-image-7 .bg-image {
    width: 490px;
    right: -80px;
  }
  .features-section .feature-item .feature-image-7 .big-chart {
    top: 80px;
    right: 0px;
    width: 500px;
  }
  .features-section .feature-item .feature-image-7 .circle-chart-1 {
    width: 40px;
    right: 270px;
    bottom: 90px;
  }
  .features-section .feature-item .feature-image-7 .circle-chart-2 {
    width: 40px;
    right: 220px;
    bottom: 90px;
  }
  .features-section .feature-item .feature-image-7 .circle-chart-3 {
    width: 40px;
    right: 170px;
    bottom: 90px;
  }
  .features-section .feature-item .feature-image-7 .man-image-1 {
    left: 25px;
    width: 155px;
    bottom: 65px;
  }
  .features-section .feature-item .feature-image-7 .man-image-2 {
    left: -95px;
    bottom: 65px;
    width: 195px;
  }
  .features-section .feature-item .feature-image-7 .man-image-3 {
    bottom: 60px;
    width: 165px;
    right: -30px;
  }
  .features-section .feature-item .feature-image-7 .leaf-image {
    width: 75px;
    bottom: 60px;
    right: -60px;
  }

  .features-section .feature-item:nth-child(2) .feature-image-7 .bg-image {
    width: 500px;
    left: -60px;
  }
  .features-section .feature-item:nth-child(2) .feature-image-7 .big-chart {
    left: 0px;
    top: 100px;
    width: 500px;
  }
  .features-section .feature-item:nth-child(2) .feature-image-7 .man-image-1 {
    top: 5px;
    left: 160px;
    width: 280px;
  }
  .features-section .feature-item:nth-child(2) .feature-image-7 .man-image-3 {
    right: 0px;
    bottom: 30px;
    width: 360px;
  }
  .features-section .feature-item:nth-child(2) .feature-image-7 .leaf-image {
    right: -70px;
    bottom: 30px;
    width: 200px;
  }
  .features-section .feature-item:nth-child(2) .feature-image-7 .man-image-2 {
    left: -60px;
    bottom: 30px;
    width: 195px;
  }
  /* home 7 pages - end
	================================================== */

  /* home 9 pages - start
	================================================== */
  .banner-section .sass-banner-3 {
    padding-top: 240px;
    padding-bottom: 130px;
  }
  .banner-section .sass-banner-3 .banner-content {
    padding-top: 0px;
  }
  .banner-section .sass-banner-3 .banner-item-image .laptop-image {
    width: 820px;
    margin-left: -120px;
  }
  /* home 9 pages - end
	================================================== */

  /* home 10 pages - start
	================================================== */
  .banner-section .sass-banner-4 .banner-content {
    padding-top: 0px;
  }
  .banner-section .sass-banner-4 {
    height: auto;
    padding-top: 260px;
    padding-bottom: 120px;
  }
  .banner-section .sass-banner-4 .banner-item-image .bg-image {
    top: -630px;
    right: -525px;
    width: 1000px;
  }
  .banner-section .sass-banner-4 .banner-item-image .laptop-image {
    width: 600px;
    margin-left: 0px;
  }
  .banner-section .sass-banner-4 .banner-item-image .popup-video {
    top: 35%;
    left: 55%;
  }
  .banner-section .sass-banner-4 .banner-item-image .shape-image {
    top: -55px;
    left: 30px;
  }

  .features-section .feature-item .feature-image-10 {
    float: none !important;
  }
  .features-section .feature-item .feature-image-10 .big-image {
    width: 580px;
    margin-left: -40px;
  }
  .features-section .feature-item .feature-image-10 .small-image-1 {
    top: 76px;
    left: -100px;
  }
  .features-section .feature-item .feature-image-10 .small-image-2 {
    right: -80px;
    bottom: -75px;
  }
  .features-section .feature-item .feature-image-10 .shape-1 {
    top: -33px;
    left: 15px;
  }
  .features-section .feature-item .feature-image-10 .shape-2 {
    left: 90px;
    bottom: 0px;
  }

  .features-section .feature-item:nth-child(2) .feature-image-10 .big-image {
    margin-left: -60px;
    margin-right: 0px;
  }
  .features-section
    .feature-item:nth-child(2)
    .feature-image-10
    .small-image-1 {
    top: 42px;
    left: 0px;
  }
  .features-section .feature-item:nth-child(2) .feature-image-10 .shape-2 {
    left: 120px;
    bottom: 10px;
  }
  .features-section .feature-item:nth-child(2) .feature-image-10 .shape-3 {
    top: 56%;
    right: 0px;
    width: 50px;
  }
  /* home 10 pages - end
	================================================== */

  /* home 11 pages - start
	================================================== */

  /* blank */

  /* home 11 pages - end
	================================================== */

  /* home 12 pages - start
	================================================== */
  .search-section .overlay-color {
    padding: 150px 0px;
  }
  /* home 12 pages - end
	================================================== */

  /* app details 1 pages - start
	================================================== */
  /* app details 1 pages - end
	================================================== */

  /* app details 2 pages - start
	================================================== */
  /* app details 2 pages - end
	================================================== */

  /* pricing pages - start
	================================================== */
  .pricing-section .bg-image {
    max-width: 100%;
  }
  /* pricing pages - end
	================================================== */

  /* contact pages - start
	================================================== */
  .map-section .contact-form {
    right: 90px;
  }
  /* contact pages - end
	================================================== */

  /* 404 error pages - start
	================================================== */
  .error-section .common-container {
    padding: 0px 170px;
  }

  .common-page .common-container .shape-1 {
    top: 44%;
  }
  .common-page .common-container .shape-3 {
    top: 40px;
    right: 130px;
  }
  .common-page .common-container .shape-6 {
    top: 70%;
    left: 58%;
  }
  .common-page .common-container .shape-9 {
    right: 180px;
    bottom: 60px;
  }
  .common-page .common-container .shape-2 {
    top: 15%;
    left: 35%;
  }
  /* 404 error pages - end
	================================================== */

  /* coming soon pages - start
	================================================== */
  .coming-soon-section .common-container {
    padding: 0px 100px;
  }
  .coming-soon-section {
    .common-container {
      .item-content {
        .title-text {
          font-size: 36px;
          margin-bottom: 15px;
        }
        .countdown-timer {
          ul {
            li {
              margin-right: 30px;
              padding-right: 30px;
              &:last-child {
                margin-right: 0px;
                padding-right: 0px;
              }
              strong {
                font-size: 60px;
              }
            }
          }
        }
      }
    }
  }
  /* coming soon pages - end
	================================================== */

  /* register pages - start
	================================================== */
  .register-section .common-container {
    padding: 0px 130px;
  }
  /* register pages - end
	================================================== */
}

/* ====================================================================================================
1 - media screen and (max-width: 1440px) - end
==================================================================================================== */

/*  ====================================================================================================
2 - media screen and (max-width: 1199px) - start
==================================================================================================== */

@media screen and (max-width: 1199px) {
  /* global reset - start
	================================================== */
  .section-title .paragraph-text {
    font-size: 14px;
    line-height: 24px;
  }
  .section-title .title-text {
    font-size: 30px;
  }
  /* global reset - end
	================================================== */

  /* header section - start
	================================================== */
  .header-section .btns-group > ul > li .custom-btn {
    padding: 0px 20px;
  }
  .home-page-7 .header-section .btns-group > ul > li .mobile-menu-btn {
    margin-left: 0px;
  }
  /* header section - end
	================================================== */

  /* header section - start
	================================================== */
  .footer-section .footer-content {
    margin-bottom: 60px;
  }
  /* header section - end
	================================================== */

  /* footer section - start
	================================================== */
  .footer-section {
    background-size: 100% 40%;
  }
  /* footer section - end
	================================================== */

  /* breadcrumb section - start
	================================================== */
  .breadcrumb-section .section-title .title-text {
    font-size: 30px;
  }
  .breadcrumb-section .section-title .paragraph-text {
    font-size: 14px;
    max-width: 100%;
  }
  .breadcrumb-section {
    .design-image-1 {
      right: 15px;
    }
    .cross-image-1,
    .cross-image-2,
    .box-image-1,
    .box-image-2,
    .flow-image-1,
    .flow-image-2,
    .circle-half-1,
    .circle-half-2,
    .circle-image-1 {
      display: none;
    }
  }
  /* breadcrumb section - end
	================================================== */

  /* home 1 pages - start
	================================================== */
  .banner-section .mobile-app-banner-1 .banner-image .bg-image {
    width: 370px;
    margin-right: 0px;
  }
  .banner-section .mobile-app-banner-1 .banner-image .phone-image {
    right: 160px;
    width: 150px;
    top: 130px !important;
  }
  .banner-section .mobile-app-banner-1 .banner-image .man-image-1 {
    width: 135px;
    left: 0px !important;
  }
  .banner-section .mobile-app-banner-1 .banner-image .man-image-2 {
    width: 135px;
    right: 50px;
  }
  .banner-section .mobile-app-banner-1 .banner-image .man-image-3 {
    right: 95px;
    width: 115px;
    top: 0px !important;
  }
  .banner-section .mobile-app-banner-1 .banner-image .cog-image {
    width: 45px;
    top: 70px !important;
    left: 30% !important;
  }
  .banner-section .mobile-app-banner-1 .banner-image .leaf-image-1 {
    z-index: 0;
    width: 130px;
    position: absolute;
    top: 90px !important;
    left: 0px !important;
  }
  .banner-section .mobile-app-banner-1 .banner-image .leaf-image-2 {
    right: 70px;
    width: 130px;
    top: 160px !important;
  }
  .banner-section .mobile-app-banner-1 .banner-content > h2 {
    font-size: 36px;
    line-height: 42px;
  }
  .features-section .feature-item .feature-content .section-title .title-text {
    font-size: 30px;
  }
  .features-section .feature-item .feature-content > p {
    font-size: 14px;
  }

  .features-section .feature-item .feature-image-1 .circle-image {
    width: 350px;
    display: table;
    margin: 0px auto;
  }
  .features-section .feature-item .feature-image-1 .phone-image {
    top: -25px;
    left: 48px;
    width: 300px;
  }
  .features-section .feature-item:nth-child(2) .feature-image-1 .phone-image {
    top: -29px;
    right: 50px;
  }

  .app-section,
  .features-section {
    overflow: hidden;
  }

  .blog-section .blog-carousel:after,
  .blog-section .blog-carousel:before {
    display: none;
  }
  /* home 1 pages - end
	================================================== */

  /* home 2 pages - start
	================================================== */
  .banner-section .mobile-app-banner-2 .banner-content > h2 {
    font-size: 40px;
    line-height: 54px;
  }
  .features-section .feature-item .feature-image-2 .item-image {
    width: 480px;
  }
  .features-section .feature-item .feature-content .feature-item-title {
    font-size: 30px;
    margin-bottom: 30px;
  }
  .features-section
    .feature-item
    .feature-content
    .service-list
    > ul
    > li
    .item-icon {
    margin-right: 20px;
  }
  .home-page-2 .features-section .feature-item .feature-content {
    padding-top: 0px;
  }
  .features-section .feature-item .feature-image-2 .bg-image {
    width: 350px;
    margin-top: 110px;
    margin-right: 0px;
  }
  .features-section .feature-item .feature-image-2 .phone-image {
    right: 70px;
    width: 280px;
    top: 10px;
  }
  .features-section .feature-item .feature-image-2 .comment-image-1,
  .features-section .feature-item .feature-image-2 .comment-image-2 {
    width: 250px;
    position: absolute;
  }
  .features-section .feature-item .feature-image-2 .comment-image-1 {
    top: 130px;
    right: 150px;
  }
  .features-section .feature-item .feature-image-2 .comment-image-2 {
    right: 40px;
    bottom: 60px;
  }
  .features-section .feature-item .feature-image-2 .shape-image-1 {
    top: 25px;
    left: 40px;
  }
  .features-section .feature-item .feature-image-2 .shape-image-2 {
    top: 50%;
    right: 51px;
  }
  .features-section .feature-item .feature-image-2 .shape-image-3 {
    left: 60px;
    bottom: 0;
  }
  .features-section .feature-item:nth-child(2) .feature-image-2 .phone-image {
    left: 40px;
    right: unset;
  }
  .features-section
    .feature-item:nth-child(2)
    .feature-image-2
    .comment-image-1,
  .features-section
    .feature-item:nth-child(2)
    .feature-image-2
    .comment-image-2 {
    width: 250px;
    position: absolute;
  }
  .features-section
    .feature-item:nth-child(2)
    .feature-image-2
    .comment-image-2 {
    left: 120px;
    right: unset;
  }
  .features-section .feature-item:nth-child(2) .feature-image-2 .shape-image-2 {
    right: 60px;
  }
  .features-section .feature-item:nth-child(2) .feature-image-2 .shape-image-3 {
    left: 0;
    bottom: 0px;
  }
  .app-section .app-image-2 {
    padding-top: 60px;
  }
  .app-section .app-image-2 .bg-image {
    width: 330px;
  }
  .app-section .app-image-2 .phone-image {
    right: 0px;
    width: 300px;
    top: -20px;
  }
  .app-section .app-image-2 .shape-image-1 {
    left: 20px;
    top: 60px;
  }
  .app-section .app-image-2 .shape-image-2 {
    right: 0;
    top: 220px;
  }
  .app-section .app-image-2 .shape-image-3 {
    left: 34px;
    bottom: -40px;
  }
  /* home 2 pages - end
	================================================== */

  /* home 3 pages - start
	================================================== */
  .slider-section .mobileapp-main-carousel {
    width: 100%;
    margin: 0 auto;
  }
  .slider-section .mobileapp-main-carousel .item.first-item h2 {
    font-size: 30px;
    line-height: 42px;
  }
  .slider-section
    .mobileapp-main-carousel
    .item.second-item
    .slider-content
    .title-text {
    font-size: 40px;
  }
  .slider-section .mobileapp-main-carousel .item.second-item .slider-content {
    padding-top: 0px;
  }
  .slider-section
    .mobileapp-main-carousel
    .item.second-item
    .slider-image
    .phone-image {
    width: 250px;
    display: table;
    margin: 0px auto;
  }
  .slider-section
    .mobileapp-main-carousel
    .item.second-item
    .slider-image
    .commentbar-image-1,
  .slider-section
    .mobileapp-main-carousel
    .item.second-item
    .slider-image
    .commentbar-image-2 {
    width: 220px;
    position: absolute;
  }
  .slider-section
    .mobileapp-main-carousel
    .item.second-item
    .slider-image
    .commentbar-image-1 {
    top: 40px !important;
    left: -10px !important;
  }
  .slider-section
    .mobileapp-main-carousel
    .item.second-item
    .slider-image
    .commentbar-image-2 {
    top: 250px !important;
    left: -10px !important;
  }
  .slider-section .mobileapp-main-carousel .item {
    height: 600px;
    background-size: cover;
  }
  .slider-section .mobileapp-main-carousel .item.first-item .phone-image {
    width: 230px;
    display: table;
    margin: 0 auto 0px;
  }

  .features-section .feature-item .feature-image-3 .bg-image {
    width: 350px;
    display: table;
    margin: 0px auto;
  }
  .features-section .feature-item .feature-image-3 {
    padding-top: 130px;
  }
  .features-section .feature-item .feature-image-3 .phone-image {
    z-index: 1;
    left: 70px;
    top: 50px;
    width: 220px;
    position: absolute;
  }
  .features-section .feature-item .feature-image-3 .comment-image {
    z-index: 2;
    top: 135px;
    left: 138px;
    width: 80px;
    position: absolute;
  }
  .features-section .feature-item .feature-image-3 .leaf-image-2 {
    top: 10px;
    right: 40px;
    width: 140px;
    position: absolute;
  }
  .features-section .feature-item .feature-image-3 .man-image-2 {
    z-index: 2;
    right: 35px;
    bottom: 0px;
    width: 125px;
    position: absolute;
  }
  .features-section .feature-item .feature-image-3 .man-image-1 {
    z-index: 2;
    left: 20px;
    bottom: 0px;
    width: 90px;
    position: absolute;
  }
  .features-section .feature-item:nth-child(2) .feature-image-3 .bg-image {
    width: 350px;
    display: table;
    margin: 0px auto;
  }
  .features-section .feature-item:nth-child(2) .feature-image-3 .phone-image {
    top: 100px;
    left: 115px;
    width: 175px;
    position: absolute;
  }
  .features-section .feature-item:nth-child(2) .feature-image-3 .leaf-image-1 {
    top: 35px;
    left: 15px;
    width: 200px;
    position: absolute;
  }
  .features-section .feature-item:nth-child(2) .feature-image-3 .leaf-image-2 {
    top: 25px;
    right: 10px;
    width: 200px;
    position: absolute;
  }
  .features-section .feature-item:nth-child(2) .feature-image-3 .man-image-1 {
    left: 10px;
    bottom: -20px;
    width: 140px;
    position: absolute;
  }
  .features-section .feature-item:nth-child(2) .feature-image-3 .man-image-2 {
    right: 15px;
    width: 125px;
    bottom: -10px;
    position: absolute;
  }
  /* home 3 pages - end
	================================================== */

  /* home 4 pages - start
	================================================== */
  .banner-section .mobile-app-banner-4 .banner-content > h2 {
    font-size: 38px;
    line-height: 50px;
  }
  .banner-section .mobile-app-banner-4 .banner-image .phone-image {
    width: 390px;
    margin-right: 0px;
  }
  .banner-section .mobile-app-banner-4 .banner-image .man-image-1 {
    width: 160px;
    top: 10px !important;
    left: 40px !important;
  }
  .banner-section .mobile-app-banner-4 .banner-image .sms-image-1 {
    width: 101px;
    top: 75px !important;
    left: 130px !important;
  }
  .banner-section .mobile-app-banner-4 .banner-image .man-image-2 {
    width: 170px;
    top: 23px !important;
    right: 96px !important;
  }
  .banner-section .mobile-app-banner-4 .banner-image .sms-image-2 {
    width: 120px;
    top: -26px !important;
    right: 120px !important;
  }
  .banner-section .mobile-app-banner-4 .banner-image .tree-image {
    right: 180px;
    bottom: 20px;
    width: 130px;
  }
  .banner-section .mobile-app-banner-4 .bg-shape-image {
    top: 0px;
    right: 0px;
    width: 66%;
  }

  .features-section .feature-item .feature-image-4 .phone-image {
    width: 200px;
  }
  .features-section .feature-item .feature-image-4 .floor-image {
    bottom: 15px;
    width: 350px;
    left: 0px;
  }
  .features-section .feature-item .feature-image-4 .man-image-1 {
    bottom: 40px;
    left: 0px;
    width: 120px;
  }
  .features-section .feature-item .feature-image-4 .comment-1-image {
    top: 85px;
    left: 25px;
    width: 190px;
  }
  .features-section .feature-item .feature-image-4 .comment-2-image {
    top: 185px;
    left: 95px;
    width: 190px;
  }
  .features-section .feature-item .feature-image-4 .flower-image-2 {
    width: 50px;
    bottom: 37px;
    right: 40px;
  }
  .features-section .feature-item:nth-child(2) .feature-image-4 .box-image-1 {
    top: 50px;
    left: 120px;
    width: 60px;
  }
  .features-section .feature-item:nth-child(2) .feature-image-4 .box-image-2 {
    top: 130px;
    right: 130px;
    width: 60px;
  }
  .features-section .feature-item:nth-child(2) .feature-image-4 .box-image-3 {
    top: 220px;
    left: 130px;
    width: 58px;
  }
  .features-section .feature-item:nth-child(2) .feature-image-4 .man-image-1 {
    bottom: 0px;
    right: 60px;
    width: 125px;
  }
  .features-section .feature-item:nth-child(2) .feature-image-4 .man-image-2 {
    bottom: 0px;
    left: -75px;
    width: 280px;
  }
  .features-section .feature-item:nth-child(2) .feature-image-4 .flower-image {
    width: 80px;
    right: 10px;
    bottom: 100px;
  }
  /* home 4 pages - end
	================================================== */

  /* home 5 pages - start
	================================================== */
  .banner-section .mobile-app-banner-5 .banner-content .title-text {
    font-size: 40px;
  }
  .banner-section .mobile-app-banner-6 .banner-image .phone-image {
    width: 400px;
    margin-right: -15px;
  }
  .working-process-section .process-item {
    margin-left: -15px;
    margin-right: -15px;
  }
  .banner-section .mobile-app-banner-6 .banner-image .commentbar-image-1 {
    top: 125px !important;
    left: -220px !important;
  }
  .banner-section .mobile-app-banner-6 .banner-image .commentbar-image-2 {
    top: 340px !important;
    left: -210px !important;
  }

  .features-section .feature-item .feature-image-6 .bg-image {
    width: 430px;
    margin-left: 0;
  }
  .features-section .feature-item .feature-image-6 .phone-image {
    top: 0px;
    left: 70px;
    width: 330px;
  }
  .features-section .feature-item .feature-image-6 .comment-image-1,
  .features-section .feature-item .feature-image-6 .comment-image-2 {
    width: 320px;
  }
  .features-section .feature-item .feature-image-6 .comment-image-1 {
    top: 140px;
    left: 140px;
  }
  .features-section .feature-item .feature-image-6 .comment-image-2 {
    left: 150px;
    bottom: 60px;
  }
  .features-section .feature-item .feature-image-6 .popup-video {
    top: 50%;
    right: unset;
    left: 50%;
    transform: translate(-50%, -50%);
  }
  .home-page-6 .features-section .feature-item .feature-content {
    padding: 0px;
  }
  .features-section .feature-item:nth-child(2) .feature-image-6 .bg-image {
    margin-left: 0px;
    margin-right: 0;
  }
  .features-section
    .feature-item:nth-child(2)
    .feature-image-6
    .comment-image-1,
  .features-section
    .feature-item:nth-child(2)
    .feature-image-6
    .comment-image-2 {
    width: 320px;
  }
  .features-section
    .feature-item:nth-child(2)
    .feature-image-6
    .comment-image-1 {
    top: 60px;
    left: 0;
  }
  .features-section
    .feature-item:nth-child(2)
    .feature-image-6
    .comment-image-2 {
    left: 0;
  }
  /* home 5 pages - end
	================================================== */

  /* home 6 pages - start
	================================================== */
  .banner-section .mobile-app-banner-6 .banner-content h2 {
    font-size: 36px;
  }
  /* home 6 pages - end
	================================================== */

  /* home 7 pages - start
	================================================== */
  .banner-section .sass-banner-1 .banner-item-image .child-img-1 {
    top: -14px;
    left: 320px;
    width: 350px;
  }
  .banner-section .sass-banner-1 .banner-item-image .child-img-2 {
    left: 0px;
    bottom: -17px;
    width: 380px;
  }
  .banner-section .sass-banner-1 .banner-item-image .child-img-3 {
    right: 60px;
    bottom: -17px;
    width: 380px;
  }
  .home-page-7 .banner-section {
    margin-bottom: 0px;
  }
  .banner-section .sass-banner-1 .banner-item-image {
    z-index: 2;
    margin-bottom: 0px;
  }

  .features-section .feature-item .feature-image-7 .bg-image {
    right: 0px;
    width: 450px;
  }
  .features-section .feature-item .feature-image-7 .big-chart {
    top: 80px;
    right: 0px;
    width: 450px;
  }
  .features-section .feature-item .feature-image-7 .man-image-1 {
    left: 30px;
    width: 140px;
    bottom: 65px;
  }
  .features-section .feature-item .feature-image-7 .man-image-2 {
    left: -60px;
    bottom: 65px;
    width: 170px;
  }
  .features-section .feature-item .feature-image-7 .man-image-3 {
    bottom: 60px;
    width: 150px;
    right: -15px;
  }
  .features-section .feature-item .feature-image-7 .leaf-image {
    width: 70px;
    bottom: 60px;
    right: -10px;
  }

  .features-section .feature-item:nth-child(2) .feature-image-7 .bg-image {
    left: 0px;
    width: 450px;
  }
  .features-section .feature-item:nth-child(2) .feature-image-7 .big-chart {
    left: 15px;
    top: 100px;
    width: 450px;
  }
  .features-section .feature-item:nth-child(2) .feature-image-7 .man-image-2 {
    left: 0px;
    bottom: 30px;
    width: 150px;
  }
  .features-section .feature-item:nth-child(2) .feature-image-7 .man-image-3 {
    right: 0px;
    bottom: 30px;
    width: 310px;
  }
  .features-section .feature-item:nth-child(2) .feature-image-7 .man-image-1 {
    top: 0px;
    left: 160px;
    width: 280px;
  }
  /* home 7 pages - end
	================================================== */

  /* home 8 pages - start
	================================================== */
  .banner-section .sass-banner-2 .banner-content .title-text {
    font-size: 30px;
    line-height: 42px;
  }
  /* home 8 pages - end
	================================================== */

  /* home 9 pages - start
	================================================== */
  .banner-section .sass-banner-3 .banner-content .title-text {
    font-size: 36px;
    line-height: 54px;
  }
  /* home 9 pages - end
	================================================== */

  /* home 10 pages - start
	================================================== */
  .banner-section .sass-banner-4 .banner-content .title-text {
    font-size: 42px;
    line-height: 54px;
  }
  .features-section .feature-item .feature-image-10 .big-image {
    width: 450px;
    margin-left: 0px;
  }
  .features-section .feature-item .feature-image-10 .small-image-2 {
    right: -80px;
    bottom: -75px;
    width: 250px;
  }
  .features-section .feature-item .feature-image-10 .small-image-1 {
    top: 60px;
    left: -20px;
    width: 250px;
  }
  .features-section .feature-item .feature-image-10 .shape-1 {
    top: -20px;
    left: 40px;
    width: 60px;
  }
  .features-section .feature-item .feature-image-10 .shape-2 {
    left: 130px;
    bottom: 0px;
    width: 60px;
  }
  /* home 10 pages - end
	================================================== */

  /* home 11 pages - start
	================================================== */
  .home-page-11 .slider-section .owl-theme .owl-nav .owl-prev,
  .home-page-11 .slider-section .owl-theme .owl-nav .owl-next {
    position: relative;
    transform: unset;
    left: 0px;
    right: 0px;
  }
  .home-page-11 .slider-section .owl-theme .owl-nav {
    margin-top: 30px;
  }
  .slider-section .appstore-main-carousel .item .item-content h1 {
    font-size: 36px;
    line-height: 54px;
    margin-bottom: 20px;
  }
  .slider-section
    .appstore-main-carousel
    .item
    .item-content
    .btns-group
    > ul
    > li {
    margin-right: 5px;
  }

  .mobile-app-section .app-item {
    width: 100%;
    display: table;
    margin: 50px auto 0px;
  }
  .mobile-app-section .app-item .app-content {
    padding: 20px 15px;
  }
  /* home 11 pages - end
	================================================== */

  /* home 12 pages - start
	================================================== */
  .search-section .banner-content h1 {
    font-size: 42px;
    line-height: 54px;
    margin-bottom: 30px;
  }
  .search-section .banner-content h1 {
    font-size: 32px;
    line-height: 42px;
    margin-bottom: 20px;
  }
  .search-section .overlay-color {
    padding: 100px 0px;
  }
  .mobile-app-section .app-nav > ul {
    margin-bottom: 60px;
  }
  .mobile-app-section
    .tab-content
    .tab-pane
    .top-content-bar
    .option-form
    .form-control {
    height: 50px;
    display: table;
    font-size: 16px;
    max-width: 320px;
    margin: 30px auto 0px;
  }
  .mobile-app-section .tab-content .tab-pane .top-content-bar .tab-pane-title {
    text-align: center;
  }
  /* home 12 pages - end
	================================================== */

  /* app details 1 pages - start
	================================================== */
  .sidebar-section.app-details-sidebar .widget_search .form-item .search-btn {
    padding: 0px 15px;
  }
  /* app details 1 pages - end
	================================================== */

  /* app details 2 pages - start
	================================================== */
  /* app details 2 pages - end
	================================================== */

  /* about page - start
	================================================== */
  .team-section .team-member-bordered {
    width: 220px;
    padding: 40px 15px;
  }
  .about-section .about-content > p {
    font-size: 14px;
  }
  .about-section .about-image .item-image {
    width: 500px;
    margin-left: -60px;
  }
  .about-section .about-image .popup-video {
    right: -35px;
  }
  .testimonial-section
    .testimonial-carousel
    .item
    .testimonial-content
    .hero-info {
    margin-bottom: 30px;
  }
  .testimonial-section
    .testimonial-carousel
    .item
    .testimonial-content
    .paragraph-text {
    font-size: 18px;
    line-height: 30px;
  }
  /* about page - end
	================================================== */

  /* testimonial pages - start
	================================================== */
  .testimonial-section .review-item .reviewer-content .reviewer-comment {
    font-size: 18px;
    line-height: 30px;
  }
  .testimonial-section .review-item .reviewer-content .reviewer-info {
    margin-bottom: 30px;
  }
  /* testimonial pages - end
	================================================== */

  /* team pages - start
	================================================== */
  .team-section .team-member-grid {
    width: 230px;
  }
  /* team pages - end
	================================================== */

  /* contact pages - start
	================================================== */
  .map-section .contact-form {
    right: 15px;
  }
  /* contact pages - end
	================================================== */

  /* blog pages - start
	================================================== */
  .blog-section .blog-grid-item .blog-content .blog-title .title-link {
    font-size: 18px;
  }
  /* blog pages - end
	================================================== */

  /* 404 error pages - start
	================================================== */
  .common-page .common-container {
    width: 100%;
    padding: 0px 30px;
    text-align: center;
    &:first-child {
      display: none;
    }
    .item-content {
      margin: auto;
    }
  }
  .common-page .brand-logo {
    left: 0px;
    right: 0px;
    width: 100%;
    text-align: center;
  }

  .common-page .social-links {
    left: 0px;
    right: 0px;
    width: 100%;
    display: table;
    text-align: center;
    > ul {
      display: table;
      margin: 0 auto;
    }
  }
  /* 404 error pages - end
	================================================== */

  /* coming soon pages - start
	================================================== */
  .coming-soon-section {
    .common-container {
      .item-content {
        .countdown-timer {
          ul {
            display: table;
            margin: 0 auto;
            text-align: center;
          }
        }
      }
    }
  }
  .coming-soon-section .common-container .item-content .email-form .form-item {
    margin: 0 auto 30px;
  }
  /* coming soon pages - end
	================================================== */

  /* register pages (login) - start
	================================================== */
  .register-section
    .common-container
    .item-content
    .register-form
    .forgot-password {
    text-align: center !important;
  }
  /* register pages (login) - end
	================================================== */
}

/* ====================================================================================================
2 - media screen and (max-width: 1199px) - end
==================================================================================================== */

/* ====================================================================================================
3 - media screen and (max-width: 991px) - start --->>>for medium device
==================================================================================================== */

@media screen and (max-width: 991px) {
  /* global reset - start
	================================================== */
  .section-title {
    margin-bottom: 60px;
  }
  .sec-ptb-160 {
    padding: 100px 0px;
  }
  /* global reset - end
	================================================== */

  /* header section - start
	================================================== */
  .header-section.black-content,
  .header-section.white-content {
    .col-lg-7 {
      display: none;
    }
  }

  .header-section.black-content {
    /* home 1 */
    .col-lg-8 {
      display: none;
    }
  }
  .header-section .btns-group > ul > li.d-none {
    display: inline-block !important;
  }
  .home-page-7 .header-section .btns-group > ul > li .mobile-menu-btn {
    margin-left: 30px;
  }
  .home-page-7 .banner-section:after {
    display: none;
  }
  /* header section - end
	================================================== */

  /* footer section - start
	================================================== */
  .footer-section .footer-content .useful-links,
  .footer-section .footer-content .about-content {
    margin-bottom: 60px;
  }
  .funfact-section .bg-image {
    background-size: 95% 100%;
  }
  .footer-section,
  .home-page-1 .footer-section {
    background-size: 100% 15%;
  }
  /* footer section - end
	================================================== */

  /* breadcrumb section - start
	================================================== */
  .breadcrumb-section {
    .design-image-1,
    .design-image-2 {
      display: none;
    }
  }
  .breadcrumb-section .breadcrumb-content {
    text-align: center;
  }
  .breadcrumb-section .breadcrumb-nav > .breadcrumb {
    display: table;
    margin: 0 auto;
    text-align: center;
  }
  .breadcrumb-section .breadcrumb-nav > .breadcrumb > .breadcrumb-item {
    float: left;
    display: inline-block;
  }
  .breadcrumb-section .section-title {
    margin-bottom: 60px;
  }
  .breadcrumb-section .section-title .paragraph-text {
    font-size: 14px;
    max-width: 100%;
    margin: 0px auto;
  }
  .breadcrumb-section {
    padding-top: 160px;
    padding-bottom: 60px;
  }
  /* breadcrumb section - end
	================================================== */

  /* sidebar section - start
	================================================== */
  .sidebar-section .widget_instagram > ul > li {
    width: 100px;
    height: 100px;
  }
  /* sidebar section - end
	================================================== */

  /* home 1 pages - start
	================================================== */

  .mobile-banner-image.d-none {
    margin-bottom: 30px;
    display: block !important;
  }

  .banner-section .mobile-app-banner-1 .banner-image {
    display: none;
  }
  .banner-section .mobile-app-banner-1 {
    padding-top: 145px;
    padding-bottom: 100px;
  }
  .banner-section .mobile-app-banner-1 .banner-content {
    padding-top: 0px;
    text-align: center;
  }
  .banner-section .mobile-app-banner-1 .banner-content > h2 {
    font-size: 40px;
    line-height: 42px;
  }
  .banner-section .mobile-app-banner-1 .banner-content .btns-group > ul {
    display: table;
    margin: 0 auto;
  }
  .client-section .owl-theme .owl-nav {
    margin-top: 60px;
  }
  .client-section {
    margin-bottom: 0px !important;
  }

  .working-process-section .process-steps-list > ul {
    width: 100%;
    display: table;
  }
  .working-process-section .process-steps-list > ul > li {
    width: 50%;
    margin-bottom: 40px;
    &:nth-child(3),
    &:nth-child(4) {
      margin-bottom: 0px;
    }
  }
  .working-process-section .process-steps-list > ul:before {
    display: none;
  }

  .extra-features-section .tab-content .tab-pane .feature-content {
    font-size: 14px;
    text-align: center;
    margin-bottom: 40px;
  }
  .extra-features-section
    .tab-content
    .tab-pane
    .feature-content
    .instructions-list {
    text-align: left;
  }

  .features-section .feature-item .feature-content {
    margin-top: 60px;
    text-align: center;
  }
  .features-section .feature-item:nth-child(2) .feature-content {
    margin-top: 0px;
    margin-bottom: 60px;
  }
  .features-section .feature-item .feature-content .info-list {
    text-align: left;
  }
  .features-section .feature-item .feature-image-1 .phone-image {
    left: 29px;
  }
  .features-section .feature-item:nth-child(2) .feature-image-1 .phone-image {
    right: -36px;
  }
  .features-section .feature-item .feature-content .btns-group > ul {
    display: table;
    margin: 0px auto;
  }
  .features-section .feature-item {
    margin-bottom: 100px;
  }
  .features-section .feature-item:last-child {
    margin-bottom: 0px;
  }

  .testimonial-section .testimonial-carousel .item .hero-image {
    float: none;
    width: 500px;
    display: table;
    margin: 0 auto 40px;
  }

  .app-section .app-content {
    padding: 0px;
    text-align: center;
    margin-bottom: 60px;
  }
  .app-section .app-content .btns-group > ul {
    display: table;
    margin: 0 auto;
  }
  .app-section .app-image {
    display: table;
    margin: 0 auto;
    float: none !important;
  }

  .faq-section .queistions-container .queistions-item {
    text-align: center;
  }
  /* home 1 pages - end
	================================================== */

  /* home 2 pages - start
	================================================== */
  .banner-section .mobile-app-banner-2 .shape-image-2,
  .banner-section .mobile-app-banner-2 .shape-image-3,
  .banner-section .mobile-app-banner-2 .shape-image-4 {
    display: none;
  }
  .banner-section .mobile-app-banner-2 {
    overflow: hidden;
  }
  .banner-section .mobile-app-banner-2 .mobile-banner-image > img {
    display: table;
    max-width: 370px;
    margin: 0px auto;
  }
  .banner-section .mobile-app-banner-2 .banner-image {
    display: none;
  }
  .banner-section .mobile-app-banner-2 .banner-content {
    padding-top: 0px;
    text-align: center;
  }
  .banner-section .mobile-app-banner-2 {
    height: auto;
    padding-top: 170px;
    padding-bottom: 100px;
  }
  .features-section .feature-item .feature-image-2 .item-image {
    width: 480px;
    display: table;
    margin: 0 auto;
  }
  .features-section .feature-item .feature-image-2 .popup-video {
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    right: unset;
    bottom: unset;
  }
  .features-section .feature-item .feature-content .service-list > ul > li {
    text-align: left;
  }
  .features-section .feature-item .feature-image-2 .phone-image {
    right: 40px;
    width: 265px;
    top: 0px;
  }
  .features-section .feature-item .feature-image-2 .bg-image {
    width: 350px;
    margin-top: 75px;
    margin-right: 0px;
  }
  .features-section .feature-item .feature-image-2 .comment-image-1 {
    top: 115px;
    right: 115px;
  }
  .features-section .feature-item .feature-image-2 .comment-image-2 {
    right: 0px;
    bottom: 60px;
  }
  .features-section .feature-item .feature-image-2 {
    margin-top: 60px;
  }
  .features-section .feature-item .feature-image-2 .shape-image-2 {
    top: 50%;
    right: 30px;
  }
  .features-section .feature-item:nth-child(2) .feature-image-2 .phone-image {
    left: 55px;
    right: unset;
  }
  .features-section .feature-item .feature-image-2 {
    margin-top: 0px;
    margin-bottom: 60px;
  }
  .features-section .feature-item:nth-child(2) .feature-image-2 .shape-image-3 {
    left: 50px;
    bottom: 0px;
  }
  .features-section .feature-item:nth-child(2) .feature-image-2 {
    margin-bottom: 60px;
  }
  .features-section .feature-item .feature-content {
    margin-top: 0px;
  }
  .features-section .feature-item:nth-child(2) .feature-content {
    margin-top: 0px;
    margin-bottom: 0px;
  }
  .faq-section {
    overflow: hidden;
  }
  .faq-section .faq-tabs-nav {
    margin-bottom: 60px;
  }
  .faq-section .faq-tabs-nav > .nav {
    margin-bottom: 60px;
  }
  /* home 2 pages - end
	================================================== */

  /* home 3 pages - start
	================================================== */
  .slider-section .mobileapp-main-carousel .item.first-item .phone-image,
  .slider-section .mobileapp-main-carousel .item.second-item .slider-image {
    display: none;
  }
  .slider-section .mobileapp-main-carousel .item.second-item .slider-content {
    text-align: center;
  }
  .slider-section
    .mobileapp-main-carousel
    .item.second-item
    .slider-content
    .newsletter-form {
    width: 100%;
    display: table;
  }
  .slider-section
    .mobileapp-main-carousel
    .item.first-item
    .subscribe-form
    .form-item {
    width: 100%;
    display: table;
    margin: 0 auto 20px;
  }
  .slider-section .mobileapp-main-carousel .item {
    height: auto;
    padding: 80px 0px;
  }
  .slider-section .mobileapp-main-carousel .item.first-item .subscribe-form {
    margin-bottom: 0px;
  }
  .slider-section .mobileapp-main-carousel {
    box-shadow: none;
  }
  .home-page-3 .slider-section {
    margin-bottom: 30px;
  }
  .features-section .feature-item .feature-image-3 {
    margin-top: 60px;
    padding-top: 130px;
  }
  .features-section .feature-item .feature-image-3 .man-image-2 {
    right: 0px;
  }
  .features-section .feature-item:nth-child(2) .feature-image-3 {
    margin-top: 0px;
    margin-bottom: 60px;
  }
  .features-section .feature-item:nth-child(2) .feature-image-3 .man-image-2 {
    right: -20px;
  }
  .features-section .feature-item:nth-child(2) .feature-image-3 .leaf-image-2 {
    right: -12px;
  }
  /* home 3 pages - end
	================================================== */

  /* home 4 pages - start
	================================================== */
  .banner-section .mobile-app-banner-4 .banner-image {
    display: none;
  }
  .banner-section .mobile-app-banner-4 .banner-content {
    text-align: center;
  }
  .banner-section .mobile-app-banner-4 .banner-content .btns-group > ul {
    display: table;
    margin: 0px auto;
    width: 100%;
  }
  .banner-section .mobile-app-banner-4 .bg-shape-image {
    display: none;
  }
  .features-section .feature-item .feature-image-4 .flower-image-2 {
    width: 50px;
    bottom: 40px;
    right: 10px;
  }
  .features-section .feature-item .feature-image-4 .man-image-1 {
    bottom: 40px;
    left: -20px;
    width: 120px;
  }
  .features-section .feature-item .feature-image-4 .comment-2-image {
    top: 185px;
    left: 75px;
    width: 190px;
  }
  .features-section .feature-item .feature-image-4 .shape-image-1 {
    bottom: 42px;
    left: 35px;
    width: 50px;
  }
  .features-section .feature-item .feature-image-4 .shape-image-2 {
    top: 40%;
    right: 45px;
    width: 50px;
  }
  .features-section .feature-item .feature-image-4 {
    margin-bottom: 60px;
  }
  .features-section .feature-item:nth-child(2) .feature-image-4 {
    margin-top: 60px;
    margin-bottom: 0px;
  }
  .features-section .feature-item:nth-child(2) .feature-image-4 .man-image-2 {
    bottom: 0px;
    left: -95px;
    width: 280px;
  }
  .features-section .feature-item:nth-child(2) .feature-image-4 .flower-image {
    width: 80px;
    right: 0;
    bottom: 100px;
  }
  .features-section .feature-item:nth-child(2) .feature-image-4 .man-image-1 {
    bottom: 0px;
    right: 50px;
    width: 125px;
  }
  .features-section .feature-item:nth-child(2) .feature-image-4 .box-image-3 {
    top: 220px;
    left: 110px;
    width: 58px;
  }
  .features-section .feature-item:nth-child(2) .feature-image-4 .box-image-2 {
    top: 135px;
    right: 110px;
    width: 60px;
  }
  .features-section .feature-item:nth-child(2) .feature-image-4 .box-image-1 {
    top: 50px;
    left: 110px;
    width: 60px;
  }
  /* home 4 pages - end
	================================================== */

  /* home 5 pages - start
	================================================== */
  .banner-section .mobile-app-banner-5 .banner-image {
    display: none;
  }
  .banner-section .mobile-app-banner-5 .mobile-banner-image {
    margin-bottom: 0px;
    > img {
      max-width: 370px;
      display: table;
      margin: 0px auto;
    }
  }
  .banner-section .mobile-app-banner-5 .banner-content {
    text-align: center;
  }
  .banner-section .mobile-app-banner-5 .banner-content .newsletter-form {
    width: 100%;
  }
  .banner-section .mobile-app-banner-5 {
    padding-top: 170px;
    padding-bottom: 120px;
    background-size: cover;
    background-image: linear-gradient(
      to top right,
      #ff4797,
      #ffb87e
    ) !important;
  }
  /* home 5 pages - end
	================================================== */

  /* home 6 pages - start
	================================================== */
  .banner-section .mobile-app-banner-6 .mobile-banner-image {
    > img {
      max-width: 370px;
      display: table;
      margin: 0px auto;
    }
  }
  .banner-section .mobile-app-banner-6 .banner-image {
    display: none;
  }
  .banner-section .mobile-app-banner-6 .shape-bg-image {
    display: none;
  }
  .banner-section .mobile-app-banner-6 .banner-content {
    padding-top: 50px;
    text-align: center;
  }
  .banner-section .mobile-app-banner-6 .banner-content h2 {
    font-size: 30px;
    line-height: 42px;
  }
  .banner-section .mobile-app-banner-6 .banner-content .btns-group > ul {
    display: table;
    margin: 0px auto 0px;
    width: 100%;
  }

  .service-section .service-grid-item {
    width: 360px;
    display: table;
    margin: 30px auto 0px;
  }

  .features-section .feature-item .feature-image-6 {
    margin-bottom: 60px;
  }
  .features-section .feature-item:nth-child(2) .feature-image-6 {
    margin-bottom: 0px;
    margin-top: 60px;
  }

  .features-section .feature-item .feature-image-6 .bg-image {
    width: 100%;
    display: table;
    margin: 0px auto;
  }
  .features-section .feature-item .feature-image-6 .phone-image {
    top: 15px;
    left: 55px;
    width: 270px;
  }
  .features-section .feature-item .feature-image-6 .comment-image-1 {
    top: 100px;
    left: 88px;
  }
  .features-section .feature-item .feature-image-6 .comment-image-2 {
    left: 120px;
    bottom: 65px;
  }
  .features-section .feature-item .feature-image-6 .shape-image-1 {
    top: 60px;
    left: 60px;
    width: 50px;
  }
  .features-section .feature-item .feature-image-6 .shape-image-2 {
    top: 55%;
    right: 55px;
    width: 50px;
  }
  .features-section .feature-item .feature-image-6 .shape-image-3 {
    left: 75px;
    bottom: 65px;
    width: 50px;
  }
  .features-section .feature-item .feature-image-6 .popup-video {
    width: 60px;
    height: 60px;
    font-size: 20px;
    line-height: 60px;
  }
  .features-section .feature-item:nth-child(2) .feature-image-6 .phone-image {
    right: 50px;
    top: 75px;
  }
  .features-section
    .feature-item:nth-child(2)
    .feature-image-6
    .comment-image-1 {
    top: 120px;
    left: 0px;
  }
  .features-section
    .feature-item:nth-child(2)
    .feature-image-6
    .comment-image-1,
  .features-section
    .feature-item:nth-child(2)
    .feature-image-6
    .comment-image-2 {
    width: 250px;
  }
  .features-section .feature-item:nth-child(2) .feature-image-6 .shape-image-1 {
    left: 70px;
    top: 95px;
  }
  .features-section .feature-item:nth-child(2) .feature-image-6 .shape-image-2 {
    right: 60px;
  }
  .features-section .feature-item:nth-child(2) .feature-image-6 .shape-image-3 {
    left: 70px;
    bottom: 0px;
  }

  .working-process-section .process-item {
    margin-left: 0px;
    margin-right: 0px;
  }
  .working-process-section .process-phone-image .phone-image {
    width: 360px;
  }
  /* home 6 pages - end
	================================================== */

  /* home 7 pages - start
	================================================== */
  .home-page-7 .banner-section .decoration-items {
    display: none;
  }
  .banner-section .sass-banner-1 .banner-item-image .child-img-1 {
    top: -14px;
    left: 250px;
    width: 300px;
  }
  .banner-section .sass-banner-1 .banner-item-image .child-img-2 {
    left: 0px;
    bottom: -17px;
    width: 270px;
  }
  .banner-section .sass-banner-1 .banner-item-image .child-img-3 {
    right: 60px;
    bottom: -17px;
    width: 251px;
  }
  .banner-section .sass-banner-1 .banner-item-image {
    display: none;
  }
  .banner-section .sass-banner-1 {
    padding-top: 200px;
    padding-bottom: 80px;
  }

  .software-section .software-container .software-item:nth-child(2) {
    left: 0px;
  }
  .software-section .software-container .software-item:nth-child(7) {
    right: 0px;
  }
  .software-section {
    .shape-img-1,
    .shape-img-2 {
      width: 300px;
    }
  }
  .pricing-section .bg-image .bg-shape-1 {
    display: none;
  }
  .free-trail-section .free-trail-form {
    padding: 50px 100px;
  }

  .features-section .feature-item .feature-image-7 {
    margin-top: 60px;
  }
  .features-section .feature-item:nth-child(2) .feature-image-7 {
    margin-top: 0px;
    margin-bottom: 60px;
  }
  /* home 7 pages - end
	================================================== */

  /* home 8 pages - start
	================================================== */
  .banner-section .sass-banner-2 .banner-item-image {
    display: none;
  }
  .banner-section .sass-banner-2 .banner-content {
    padding-top: 50px;
    text-align: center;
  }
  .banner-section .sass-banner-2 .banner-content .newsletter-form .form-item {
    width: 100%;
    margin-bottom: 20px;
  }

  .features-section .feature-item .feature-image-8 {
    margin-top: 60px;
  }
  .features-section .feature-item .feature-image-8 .image-1 {
    width: 100%;
    margin-right: 0px;
  }

  .features-section .feature-item .feature-image-8 .image-2 {
    left: 50px;
    top: -50px;
    width: 200px;
  }
  .features-section .feature-item .feature-image-8 .image-3 {
    right: 0px;
    width: 200px;
    bottom: -70px;
  }
  .home-page-8 .features-section .feature-item:nth-child(2) {
    margin-bottom: 120px;
  }
  .features-section .feature-item:nth-child(3) .feature-image-8 {
    margin-top: 0px;
    margin-bottom: 60px;
  }
  .features-section .feature-item:nth-child(3) .feature-image-8 .image-1 {
    width: 100%;
    margin-left: 0px;
  }
  .features-section .feature-item:nth-child(3) .feature-image-8 .image-2 {
    top: 0;
    left: 180px;
  }
  /* home 8 pages - end
	================================================== */

  /* home 9 pages - start
	================================================== */
  .banner-section .sass-banner-3 .banner-item-image {
    display: none;
  }
  .banner-section .sass-banner-3 {
    padding-top: 200px;
    padding-bottom: 100px;
    background-size: cover;
    background-image: linear-gradient(
      to top right,
      #0A0C10,
      #0A0C10
    ) !important;
  }
  .banner-section .sass-banner-3 .banner-content {
    text-align: center;
  }
  .banner-section .sass-banner-3 .banner-content .title-text {
    font-size: 34px;
    line-height: 48px;
    margin-bottom: 20px;
  }
  .banner-section .sass-banner-3 .banner-content .btns-group > ul > li {
    margin-right: 10px;
  }
  .banner-section .sass-banner-3 {
    .shape-1,
    .shape-2,
    .shape-3,
    .shape-4,
    .shape-5,
    .shape-6,
    .shape-7 {
      display: none;
    }
  }
  /* home 9 pages - end
	================================================== */

  /* home 10 pages - start
	================================================== */
  .banner-section .sass-banner-4 .banner-item-image {
    display: none;
  }
  .banner-section .sass-banner-4 .banner-content {
    text-align: center;
  }
  .banner-section .sass-banner-4 .banner-content .title-text {
    font-size: 34px;
    line-height: 42px;
    margin-bottom: 20px;
  }
  .banner-section .sass-banner-4 .banner-content .btns-group > ul {
    display: table;
    margin: 0px auto;
    width: 100%;
  }
  .banner-section .sass-banner-4 {
    padding-top: 260px;
    padding-bottom: 80px;
  }

  .features-section .feature-item .feature-image-10 {
    margin-bottom: 80px;
  }
  .features-section .feature-item:nth-child(2) .feature-image-10 {
    margin-bottom: 0px;
    margin-top: 60px;
  }
  /* home 10 pages - end
	================================================== */

  /* home 11 pages - start
	================================================== */
  .slider-section .appstore-main-carousel .item .mobile-banner-image > img {
    display: table;
    margin: 0px auto;
    max-width: 350px;
  }
  .slider-section .appstore-main-carousel .item .item-image {
    display: none;
  }
  .slider-section .appstore-main-carousel .item .item-content {
    padding-top: 0px;
    text-align: center;
  }
  .slider-section .appstore-main-carousel .item .item-content .btns-group > ul {
    width: 600px;
    display: table;
    margin: 0px auto;
  }

  .app-Category-section .Category-item .item-title {
    font-size: 21px;
    margin-bottom: 30px;
  }
  .app-Category-section .Category-item > ul > li {
    margin-bottom: 15px;
    &:last-child {
      margin-bottom: 0px;
    }
  }
  /* home 11 pages - end
	================================================== */

  /* app details 1 pages - start
	================================================== */
  .app-details-section {
    .sidebar-section {
      margin-top: 60px;
    }
  }
  /* app details 1 pages - end
	================================================== */

  /* about page - start
	================================================== */
  .about-section .about-image .item-image {
    width: 100%;
    margin: 0px;
  }
  .about-section .about-image .popup-video {
    right: 0px;
  }
  .about-section .about-content {
    padding: 0px;
    margin-top: 60px;
    text-align: center;
  }
  .service-section {
    .section-title {
      margin-bottom: 30px;
      .paragraph-text {
        width: 100%;
        margin: 0 auto;
        font-size: 18px;
        line-height: 30px;
      }
    }
  }
  .team-section {
    .section-title {
      margin-bottom: 70px;
    }
    .team-member-bordered {
      width: 100%;
      margin-top: 30px;
      padding: 60px 30px;
    }
  }
  .testimonial-section .testimonial-carousel .item.item-style-2 .hero-image {
    float: none;
    width: 500px;
    display: table;
    margin: 0 auto;
  }
  .testimonial-section .testimonial-carousel .item .testimonial-content {
    width: 80%;
    padding: 0px;
    margin: 60px auto;
    display: table;
  }
  .testimonial-section .owl-theme .owl-nav {
    width: 100%;
    position: static;
    text-align: center;
  }

  .funfact-section .section-title .title-text {
    width: 100%;
  }
  /* about page - end
	================================================== */

  /* pricing pages - start
	================================================== */
  .pricing-section {
    .bg-image {
      .pricing-plan {
        max-width: 370px;
        margin: 30px auto 0px;
      }
    }
  }
  .pricing-section .pricing-tab-nav {
    margin-bottom: 60px;
  }
  .pricing-section {
    .pricing-plan {
      max-width: 370px;
      margin: 30px auto 0px;
    }
  }
  .pricing-section .review-btn .custom-btn-underline {
    color: #138afd;
  }
  .pricing-section .review-btn .custom-btn-underline:before {
    border-bottom: 2px solid #138afd;
  }
  .pricing-section .bg-image {
    padding: 0px !important;
    background-image: none !important;
  }
  /* pricing pages - end
	================================================== */

  /* team pages - start
	================================================== */
  .team-section .team-member-grid {
    width: 100%;
  }
  .team-section
    .team-member-grid
    .member-image
    .member-social-links
    > li:nth-child(2) {
    left: 133px;
  }
  .team-section
    .team-member-grid
    .member-image
    .member-social-links
    > li:nth-child(3) {
    right: 133px;
  }
  /* team pages - end
	================================================== */

  /* faq pages - start
	================================================== */
  .faq-section .tab-content {
    padding-left: 0px;
    padding-right: 0px;
  }
  .faq-section .faq-tabs-nav {
    width: 100%;
    padding: 30px 20px;
  }
  .faq-section .faq-tabs-nav > .nav > .nav-item > .nav-link:before {
    left: -20px;
  }
  .faq-section .faq-tabs-nav > .contact-info > li {
    font-size: 14px;
  }
  /* faq pages - end
	================================================== */

  /* contact pages - start
	================================================== */
  .map-section .contact-form {
    right: 15px;
  }
  .contact-section .contact-info-item {
    width: 300px;
    margin: 30px auto 0px;
  }
  .map-section .contact-form {
    width: 100%;
    position: static;
    padding: 80px 30px;
    padding-bottom: 0px;
  }
  .map-section .google-map {
    height: 500px;
  }
  /* contact pages - end
	================================================== */

  /* blog pages - start
	================================================== */
  .blog-section {
    .sidebar-section {
      margin-top: 80px;
    }
  }
  /* blog pages - end
	================================================== */

  /* blog details pages - start
	================================================== */
  .blog-details-page-1 .breadcrumb-section .overlay-black {
    height: 400px;
  }
  .blog-details-page-1 .blog-details-section .blog-details-content {
    padding: 40px;
    margin-top: -150px;
  }
  .blog-details-section .blog-details-content .video-item {
    width: 100%;
  }
  .blog-details-section .blog-details-content .post-admin .admin-image {
    float: none;
    margin: 0px;
    display: table;
    margin-bottom: 30px;
  }
  .blog-details-section .blog-details-content .post-admin .admin-content {
    width: 100%;
  }
  .blog-details-section .blog-details-content .blockquote {
    margin: 50px 0px;
  }
  .blog-details-section .blog-details-content .tag-share-links .share-links {
    margin-top: 20px;
    float: left !important;
  }

  .blog-details-section {
    .sidebar-section {
      margin-top: 80px;
    }
  }
  /* blog details pages - end
	================================================== */
}

/* ====================================================================================================
3 - media screen and (max-width: 991px) - end --->>>for medium device
==================================================================================================== */

/* ====================================================================================================
4 - media screen and (max-width: 767px) - start --->>>For Mobile Device
==================================================================================================== */

@media screen and (max-width: 767px) {
}

/* ====================================================================================================
4 - media screen and (max-width: 767px) - end --->>>For Mobile Device
==================================================================================================== */

/* ====================================================================================================
5 - media screen and (max-width: 680px) - start
==================================================================================================== */

@media screen and (max-width: 680px) {
  
}

@media screen and (max-width: 580px) {
  /* header section - start
	================================================== */
  .header-section {
    padding: 30px 0px;
  }
  .sticky-header.stuck {
    padding: 20px 0px;
  }
  .header-section .brand-logo .brand-link {
    width: 80%;
  }

  .header-section .btns-group > ul {
    width: 100%;
  }
  .header-section .btns-group > ul > li {
    display: none;
    &:last-child {
      margin-top: -50px;
      display: inline-block;
    }
  }
  /* header section - end
	================================================== */

  /* home 12 pages - start
	================================================== */
  .home-page-12,
  .app-details-page-2 {
    padding-top: 104px;
  }
  /* home 12 pages - end
	================================================== */
}

/* ====================================================================================================
5 - media screen and (max-width: 680px) - end
==================================================================================================== */

/* ====================================================================================================
6 - media screen and (max-width: 480px) - start
==================================================================================================== */

@media screen and (max-width: 480px) {
  /* global reset - start
	================================================== */
  .sec-ptb-160 {
    padding: 80px 0px;
  }

  .section-title {
    margin-bottom: 30px;
  }
  .section-title .title-text {
    font-size: 24px;
  }
  .section-title .paragraph-text {
    font-size: 14px;
    line-height: 24px;
  }
  /* global reset - start
	================================================== */

  /* footer section - start
	================================================== */
  .footer-section .newsletter-section .newsletter-form {
    padding: 15px;
  }
  .footer-section .newsletter-section .newsletter-form .form-item #email-input {
    height: 50px;
    padding: 0px 20px;
  }
  .footer-section
    .newsletter-section
    .newsletter-form
    .form-item
    .form-item-btn {
    right: 5px;
    height: 40px;
    line-height: 40px;
    padding: 0px 15px;
  }
  .footer-section .btns-group > ul > li {
    width: 100%;
    margin: 0px;
    text-align: center;
    margin-bottom: 15px;
    &:last-child {
      margin-bottom: 0px;
    }
  }
  .footer-section .footer-bottom {
    padding: 30px 0px;
    text-align: center;
  }
  .footer-section .footer-bottom .useful-links > ul {
    float: none;
    display: table;
    text-align: center;
    margin: 15px auto 0px;
  }
  .footer-section .footer-content {
    text-align: center;
    .about-content {
      .social-links {
        > ul {
          display: table;
          margin: 0px auto;
        }
      }
    }
  }
  /* footer section - end
	================================================== */

  /* sidebar menu - start
	================================================== */
  .sidebar-menu-wrapper .sidebar-menu {
    right: -320px;
    height: 100vh;
    min-width: 300px;
    max-width: 300px;
    padding: 40px 30px;
    overflow-y: scroll;
  }
  .sidebar-menu-wrapper .sidebar-menu .btns-group > ul > li {
    margin: 0px;
    width: 100%;
    display: table;
    text-align: center;
    margin-bottom: 15px;
    &:last-child {
      margin-bottom: 0px;
    }
  }
  .sidebar-menu-wrapper .sidebar-menu .contact-info > ul > li .icon {
    margin-right: 10px;
  }
  .sidebar-menu-wrapper .sidebar-menu .contact-info > ul > li {
    font-size: 14px;
  }
  /* sidebar menu - end
	================================================== */

  /* breadcrumb section - start
	================================================== */
  .breadcrumb-section {
    padding-top: 130px;
    padding-bottom: 60px;
  }
  .breadcrumb-section .section-title .title-text {
    font-size: 24px;
  }
  /* breadcrumb section - end
	================================================== */

  /* sidebar section - start
	================================================== */
  .sidebar-section .widget_search .form-item .search-btn {
    height: 45px;
    font-size: 18px;
    padding: 0px 15px;
    line-height: 45px;
  }
  .sidebar-section .widget_instagram > ul > li {
    width: 92px;
    height: 92px;
  }
  .sidebar-section .widget_newsletter .form-item .submit-btn {
    height: 45px;
    font-size: 18px;
    padding: 0px 15px;
    line-height: 45px;
  }
  .sidebar-section .widget_categories > ul > li > a,
  .sidebar-section .widget_categories > ul > li > .badge {
    font-size: 14px;
  }
  .sidebar-section .widget_cixposttype > ul > li .item-title {
    font-size: 16px;
  }
  /* sidebar section - end
	================================================== */

  /* home 1 pages - start
	================================================== */
  .banner-section .mobile-app-banner-1 .banner-content {
    padding-top: 0;
  }
  .banner-section .mobile-app-banner-1 .banner-content > h2 {
    font-size: 26px;
    line-height: 36px;
    margin-bottom: 20px;
  }
  .banner-section .mobile-app-banner-1 .banner-content > p {
    font-size: 14px;
    margin-bottom: 30px;
  }
  .banner-section .mobile-app-banner-1 .banner-content .btns-group > ul > li {
    width: 100%;
    margin-right: 0px;
    text-align: center;
    margin-bottom: 5px;
    &:last-child {
      margin-bottom: 0px;
    }
  }

  .client-section .owl-theme .owl-nav {
    margin-top: 30px;
  }
  .client-section .client-carousel .item .client-logo {
    height: 80px;
  }

  .working-process-section .process-steps-list > ul > li {
    width: 100%;
    text-align: center;
    margin-bottom: 40px !important;
  }
  .working-process-section .process-steps-list > ul > li .item-icon {
    margin-bottom: 20px;
  }
  .extra-features-section .features-nav > .nav > .nav-item {
    width: 100%;
    margin-bottom: 40px;
    &:last-child {
      margin-bottom: 0px;
    }
  }
  .extra-features-section .tab-content .tab-pane .feature-content .item-title {
    font-size: 24px;
  }
  .extra-features-section .tab-content .tab-pane .feature-content > p {
    font-size: 14px;
  }
  .extra-features-section
    .tab-content
    .tab-pane
    .feature-content
    .instructions-list
    > ul
    > li
    > i {
    margin-right: 10px;
  }
  .extra-features-section .features-nav {
    padding-bottom: 30px;
    margin-bottom: 30px;
  }

  .features-section .feature-item .feature-image-1 .circle-image {
    width: 250px;
  }
  .features-section .feature-item .feature-image-1 .phone-image {
    top: -15px;
    left: 54px;
    width: 220px;
  }
  .features-section .feature-item .feature-image-1 .comment-image-1,
  .features-section .feature-item .feature-image-1 .comment-image-2 {
    width: 200px;
  }
  .features-section .feature-item .feature-image-1 .comment-image-1 {
    left: 85px;
    top: 73px;
  }
  .features-section .feature-item .feature-image-1 .comment-image-2 {
    left: 100px;
    bottom: 10px;
  }
  .features-section .feature-item .feature-content .btns-group > ul > li {
    width: 100%;
    margin-right: 0px;
    text-align: center;
    margin-bottom: 15px;
    &:last-child {
      margin-bottom: 0px;
    }
  }
  .features-section .feature-item .feature-content .section-title .title-text {
    font-size: 20px;
  }
  .features-section
    .feature-item
    .feature-content
    .info-list
    > ul
    > li
    .info-text {
    font-size: 14px;
    line-height: 24px;
  }
  .features-section .feature-item:nth-child(2) .feature-image-1 .phone-image {
    top: 0px;
    right: 55px;
  }
  .features-section
    .feature-item:nth-child(2)
    .feature-image-1
    .comment-image-1,
  .features-section
    .feature-item:nth-child(2)
    .feature-image-1
    .comment-image-2 {
    width: 200px;
  }
  .features-section
    .feature-item:nth-child(2)
    .feature-image-1
    .comment-image-1 {
    top: 30px;
    left: 15px;
  }
  .features-section
    .feature-item:nth-child(2)
    .feature-image-1
    .comment-image-2 {
    bottom: 15px;
    right: 10px;
  }
  .service-section .section-title {
    margin-bottom: 0px;
    .paragraph-text {
      font-size: 16px;
      line-height: 28px;
    }
  }
  .testimonial-section .testimonial-carousel .item .hero-image {
    width: 100%;
    margin: 0 auto 40px;
  }
  .testimonial-section .testimonial-carousel .item .hero-image .icon {
    top: -35px;
    right: 25px;
  }
  .testimonial-section .testimonial-carousel .item {
    padding-left: 30px;
    padding-bottom: 45px;
  }
  .app-section .app-content .section-title .title-text {
    line-height: 30px;
  }
  .section-title .title-text {
    font-size: 20px;
  }
  .app-section .app-content .btns-group > ul li {
    width: 100%;
    margin-right: 0px;
    margin-bottom: 5px;
    text-align: center;
    &:last-child {
      margin-bottom: 0px;
    }
  }
  .app-section .app-image .circle-image {
    width: 250px;
  }
  .app-section .app-image .phone-image {
    right: 16px;
    width: 220px;
  }

  .funfact-section .bg-image {
    padding: 0px;
    background-image: none !important;
  }
  .funfact-section .counter-items-list {
    margin-bottom: 40px;
  }
  .faq-section .queistions-container {
    margin-bottom: 60px;
  }
  /* home 1 pages - end
	================================================== */

  /* home 2 pages - start
	================================================== */
  .banner-section .mobile-app-banner-2 {
    height: auto;
    padding-top: 125px;
    padding-bottom: 100px;
  }
  .banner-section .mobile-app-banner-2 .mobile-banner-image > img {
    display: table;
    max-width: 320px;
    margin: 0px auto;
  }
  .banner-section .mobile-app-banner-2 .banner-content > h2 {
    font-size: 26px;
    line-height: 36px;
  }
  .banner-section .mobile-app-banner-2 .banner-content .btns-group > ul > li {
    width: 100%;
    text-align: center;
    margin-right: 0px;
    margin-bottom: 10px;
    &:last-child {
      margin-bottom: 0px;
    }
  }
  .banner-section .mobile-app-banner-2 .banner-content > p {
    font-size: 14px;
    margin-bottom: 40px;
  }
  .features-section .feature-item .feature-image-2 .item-image {
    width: 100%;
  }
  .features-section .feature-item .feature-image-2 {
    margin-top: 0px;
    margin-bottom: 0px;
  }
  .features-section .feature-item .feature-image-2 .bg-image {
    width: 250px;
    display: table;
    margin: 75px auto 0px;
  }
  .features-section .feature-item .feature-image-2 .phone-image {
    right: 60px;
    width: 200px;
    top: -50px;
  }
  .features-section .feature-item .feature-image-2 .comment-image-1,
  .features-section .feature-item .feature-image-2 .comment-image-2 {
    width: 200px;
    position: absolute;
  }
  .features-section .feature-item .feature-image-2 .comment-image-1 {
    top: 30px;
    right: 100px;
  }
  .features-section .feature-item .feature-image-2 .comment-image-2 {
    right: 15px;
    bottom: 15px;
  }
  .features-section .feature-item .feature-image-2 .shape-image-1 {
    top: 0px;
    left: 60px;
    width: 50px;
  }
  .features-section .feature-item .feature-image-2 .shape-image-2 {
    top: 50%;
    right: 50px;
    width: 50px;
  }
  .features-section .feature-item .feature-image-2 .shape-image-3 {
    left: 75px;
    bottom: -20px;
    width: 50px;
  }
  .features-section .feature-item:nth-child(2) .feature-image-2 .bg-image {
    display: table;
    margin: 75px auto 0px;
  }
  .features-section .feature-item:nth-child(2) .feature-image-2 .phone-image {
    left: 65px;
    right: unset;
  }
  .features-section
    .feature-item:nth-child(2)
    .feature-image-2
    .comment-image-1,
  .features-section
    .feature-item:nth-child(2)
    .feature-image-2
    .comment-image-2 {
    width: 200px;
    position: absolute;
  }
  .features-section
    .feature-item:nth-child(2)
    .feature-image-2
    .comment-image-1 {
    top: 0;
    left: 0;
    right: unset;
  }
  .features-section
    .feature-item:nth-child(2)
    .feature-image-2
    .comment-image-2 {
    left: 110px;
    right: unset;
  }
  .features-section .feature-item:nth-child(2) .feature-image-2 .shape-image-1 {
    left: 55px;
    top: -15px;
  }
  .features-section .feature-item:nth-child(2) .feature-image-2 .shape-image-3 {
    left: 70px;
    bottom: -20px;
  }
  .app-section .app-image-2 .bg-image {
    float: none;
    width: 250px;
    display: table;
    margin: 0 auto;
  }
  .app-section .app-image-2 {
    padding-top: 60px;
    float: none !important;
  }
  .app-section .app-image-2 .phone-image {
    top: 0px;
    width: 200px;
    right: 55px;
  }
  .app-section .app-image-2 .shape-image-1 {
    left: 70px;
    top: 30px;
    width: 50px;
  }
  .app-section .app-image-2 .shape-image-2 {
    right: 50px;
    top: 130px;
    width: 50px;
  }
  .app-section .app-image-2 .shape-image-3 {
    left: 80px;
    bottom: -10px;
    width: 50px;
  }
  .slider-section
    .mobileapp-main-carousel
    .item.second-item
    .slider-content
    .paragraph-text {
    font-size: 14px;
    margin-bottom: 30px;
  }
  /* home 2 pages - end
	================================================== */

  /* home 3 pages - start
	================================================== */
  .home-page-3 {
    padding-top: 114px;
  }
  .slider-section .mobileapp-main-carousel .item.first-item h2 {
    font-size: 18px;
    line-height: 28px;
    margin-bottom: 30px;
  }
  .slider-section
    .mobileapp-main-carousel
    .item.second-item
    .slider-content
    .title-text {
    font-size: 26px;
  }
  .slider-section
    .mobileapp-main-carousel
    .item.first-item
    .subscribe-form
    .form-item
    input,
  .slider-section
    .mobileapp-main-carousel
    .item.second-item
    .slider-content
    .newsletter-form
    .form-item
    .email-input {
    height: 50px;
    padding: 0px 20px;
  }
  .slider-section
    .mobileapp-main-carousel
    .item.first-item
    .subscribe-form
    .form-item
    .submit-btn,
  .slider-section
    .mobileapp-main-carousel
    .item.second-item
    .slider-content
    .newsletter-form
    .form-item
    .form-item-btn {
    height: 40px;
    font-size: 14px;
    line-height: 40px;
    padding: 0px 20px;
  }
  .slider-section .owl-theme .owl-dots {
    left: 50%;
    top: unset;
    right: unset;
    bottom: 20px;
    margin-top: 0px !important;
    transform: translateX(-50%);
  }
  .slider-section .owl-theme .owl-dots .owl-dot {
    margin: 0px 5px;
    display: inline-block;
  }

  .features-section .feature-item .feature-image-3 .bg-image {
    width: 300px;
  }
  .features-section .feature-item .feature-image-3 .phone-image {
    width: 200px;
  }
  .features-section .feature-item .feature-image-3 .leaf-image-1 {
    left: 0;
    width: 130px;
  }
  .features-section .feature-item .feature-image-3 .leaf-image-2 {
    top: 0;
    right: 0;
    width: 130px;
  }
  .features-section .feature-item .feature-image-3 .man-image-2 {
    right: -15px;
    width: 120px;
  }
  .features-section .feature-item .feature-image-3 .comment-image {
    width: 60px;
  }
  .features-section .feature-item:nth-child(2) .feature-image-3 .bg-image {
    width: 300px;
  }
  .features-section .feature-item:nth-child(2) .feature-image-3 .phone-image {
    left: 100px;
    width: 150px;
  }
  .features-section .feature-item:nth-child(2) .feature-image-3 .leaf-image-2 {
    top: 45px;
    right: 0px;
    width: 150px;
    position: absolute;
  }
  .features-section .feature-item:nth-child(2) .feature-image-3 .man-image-2 {
    right: 5px;
    width: 110px;
  }
  .features-section .feature-item:nth-child(2) .feature-image-3 .man-image-1 {
    left: 20px;
    width: 120px;
  }
  /* home 3 pages - end
	================================================== */

  /* home 4 pages - start
	================================================== */
  .banner-section .mobile-app-banner-4 .banner-content > h2 {
    font-size: 26px;
    line-height: 36px;
  }
  .banner-section .mobile-app-banner-4 {
    padding-top: 150px;
  }
  .banner-section .mobile-app-banner-4 .banner-content > p {
    font-size: 14px;
    margin-bottom: 40px;
  }
  .banner-section .mobile-app-banner-4 .banner-content .btns-group > ul {
    width: 100%;
  }
  .banner-section .mobile-app-banner-4 .banner-content .btns-group > ul > li {
    width: 100%;
    display: table;
    margin-right: 0px;
    text-align: center;
    margin-bottom: 10px;
    &:last-child {
      margin-bottom: 0px;
    }
  }

  .features-section .feature-item .feature-image-4 .phone-image {
    width: 140px;
  }
  .features-section .feature-item .feature-image-4 .floor-image {
    bottom: 20px;
    width: 250px;
    left: 30px;
  }
  .features-section .feature-item .feature-image-4 .man-image-1 {
    bottom: 40px;
    left: 10px;
    width: 90px;
  }
  .features-section .feature-item .feature-image-4 .flower-image-2 {
    width: 30px;
    bottom: 40px;
    right: 50px;
  }
  .features-section .feature-item .feature-image-4 .comment-1-image {
    top: 40px;
    left: 25px;
    width: 140px;
  }
  .features-section .feature-item .feature-image-4 .comment-2-image {
    top: 120px;
    left: 80px;
    width: 140px;
  }
  .features-section .feature-item .feature-image-4 .shape-image-1 {
    bottom: 42px;
    left: 60px;
    width: 30px;
  }
  .features-section .feature-item .feature-image-4 .shape-image-2 {
    top: 40%;
    right: 70px;
    width: 40px;
  }
  .features-section .feature-item:nth-child(2) .feature-image-4 {
    margin-top: 60px;
    padding-bottom: 40px;
  }
  .features-section .feature-item:nth-child(2) .feature-image-4 .box-image-1 {
    top: 40px;
    left: 120px;
    width: 30px;
  }
  .features-section .feature-item:nth-child(2) .feature-image-4 .box-image-2 {
    top: 90px;
    right: 120px;
    width: 30px;
  }
  .features-section .feature-item:nth-child(2) .feature-image-4 .box-image-3 {
    top: 150px;
    left: 120px;
    width: 30px;
  }
  .features-section .feature-item:nth-child(2) .feature-image-4 .man-image-1 {
    bottom: 0px;
    right: 70px;
    width: 90px;
  }
  .features-section .feature-item:nth-child(2) .feature-image-4 .man-image-2 {
    bottom: 0px;
    left: -10px;
    width: 180px;
  }
  .features-section .feature-item:nth-child(2) .feature-image-4 .flower-image {
    width: 60px;
    right: 30px;
    bottom: 50px;
  }
  /* home 4 pages - end
	================================================== */

  /* home 5 pages - start
	================================================== */
  .banner-section .mobile-app-banner-5 .banner-content .title-text {
    font-size: 24px;
  }
  .banner-section .mobile-app-banner-5 {
    padding-top: 130px;
    padding-bottom: 160px;
  }
  .banner-section .mobile-app-banner-5 .mobile-banner-image {
    > img {
      max-width: 270px;
    }
  }
  .banner-section .mobile-app-banner-6 .banner-content .btns-group > ul > li {
    width: 100%;
    display: table;
    margin-right: 0px;
    margin-bottom: 5px;
    text-align: center;
    &:last-child {
      margin-bottom: 0px;
    }
  }
  .banner-section .mobile-app-banner-6 .banner-content {
    padding-top: 0px;
  }
  .banner-section .mobile-app-banner-6 {
    padding-top: 150px;
  }
  .home-page-6 .banner-section {
    margin-bottom: 0px;
  }
  .service-section .service-grid-item {
    width: 100%;
    display: table;
    margin: 30px auto 0px;
  }

  .features-section .feature-item .feature-image-6 {
    float: none !important;
  }
  .features-section .feature-item .feature-image-6 .bg-image {
    width: 250px;
    display: table;
    margin: 0px auto;
  }
  .features-section .feature-item .feature-image-6 .phone-image {
    top: 25px;
    left: 85px;
    width: 200px;
  }
  .features-section .feature-item .feature-image-6 .comment-image-1,
  .features-section .feature-item .feature-image-6 .comment-image-2 {
    width: 200px;
  }
  .features-section .feature-item .feature-image-6 .comment-image-1 {
    top: 100px;
    left: 125px;
  }
  .features-section .feature-item .feature-image-6 .shape-image-1 {
    left: 80px;
  }
  .features-section .feature-item .feature-image-6 .shape-image-2 {
    right: 65px;
  }
  .features-section .feature-item .feature-image-6 .shape-image-3 {
    left: 90px;
    bottom: 55px;
  }

  .features-section .feature-item:nth-child(2) .feature-image-6 .bg-image {
    margin: 0px auto;
  }
  .features-section .feature-item:nth-child(2) .feature-image-6 .phone-image {
    top: 80px;
    right: 75px;
  }
  .features-section
    .feature-item:nth-child(2)
    .feature-image-6
    .comment-image-1,
  .features-section
    .feature-item:nth-child(2)
    .feature-image-6
    .comment-image-2 {
    width: 200px;
  }
  .features-section
    .feature-item:nth-child(2)
    .feature-image-6
    .comment-image-1 {
    top: 110px;
    left: 25px;
  }
  .features-section
    .feature-item:nth-child(2)
    .feature-image-6
    .comment-image-2 {
    left: 50px;
    bottom: 40px;
  }

  .working-process-section {
    overflow: hidden;
  }
  .working-process-section .process-item {
    padding: 0px;
    text-align: center;
  }
  .working-process-section .process-item .item-icon {
    float: none;
    height: auto;
    line-height: 1;
    display: table;
    margin: 0px auto 30px;
  }
  .working-process-section .process-item .item-content {
    width: 100%;
    display: table;
  }
  .working-process-section .process-phone-image .phone-image {
    top: 30px;
    width: 250px;
  }
  /* home 5 pages - end
	================================================== */

  /* home 6 pages - start
	================================================== */
  .banner-section .mobile-app-banner-6 .mobile-banner-image {
    > img {
      max-width: 270px;
    }
  }
  .banner-section .mobile-app-banner-6 .banner-content h2 {
    font-size: 22px;
    line-height: 30px;
    margin-bottom: 20px;
  }
  .banner-section .mobile-app-banner-6 .banner-content p {
    font-size: 14px;
    margin-bottom: 30px;
  }
  .home-page-6 .service-section {
    margin-bottom: 0px;
  }
  .features-section .feature-item .feature-image-6 .phone-image {
    top: 25px;
    left: 65px;
    width: 200px;
  }
  .features-section .feature-item .feature-image-6 .comment-image-1 {
    left: 98px;
  }
  .working-process-section .process-phone-image {
    display: none;
  }
  /* home 6 pages - end
	================================================== */

  /* home 7 pages - start
	================================================== */
  .banner-section .sass-banner-1 .banner-item-image {
    display: none;
  }
  .banner-section .sass-banner-1 .banner-content p {
    width: 100%;
    font-size: 14px;
  }
  .banner-section .sass-banner-1 .banner-content .newsletter-form .form-item {
    width: 100%;
  }
  .home-page-7 .banner-section {
    margin-bottom: 0px;
  }
  .banner-section .sass-banner-1 {
    padding-top: 150px;
  }
  .banner-section .sass-banner-1 .banner-content .title-text {
    font-size: 24px;
  }

  .features-section .feature-item .feature-image-7 {
    float: none !important;
  }

  .features-section .feature-item .feature-image-7 .bg-image {
    width: 100%;
  }
  .features-section .feature-item .feature-image-7 .big-chart {
    top: 55px;
    width: 100%;
  }
  .features-section .feature-item .feature-image-7 .man-image-1 {
    left: 30px;
    width: 95px;
    bottom: 65px;
  }
  .features-section .feature-item .feature-image-7 .man-image-2 {
    left: -50px;
    bottom: 65px;
    width: 120px;
  }
  .features-section .feature-item .feature-image-7 .man-image-3 {
    bottom: 60px;
    width: 110px;
    right: -15px;
  }
  .features-section .feature-item .feature-image-7 .leaf-image {
    width: 40px;
    bottom: 60px;
    right: -20px;
  }
  .features-section .feature-item .feature-image-7 .circle-chart-1 {
    width: 20px;
    right: 160px;
    bottom: 73px;
  }
  .features-section .feature-item .feature-image-7 .circle-chart-2 {
    width: 20px;
    right: 130px;
    bottom: 73px;
  }
  .features-section .feature-item .feature-image-7 .circle-chart-3 {
    width: 20px;
    right: 100px;
    bottom: 73px;
  }

  .features-section .feature-item:nth-child(2) .feature-image-7 .bg-image {
    width: 100%;
  }
  .features-section .feature-item:nth-child(2) .feature-image-7 .big-chart {
    left: 0;
    top: 45px;
    width: 100%;
  }
  .features-section .feature-item:nth-child(2) .feature-image-7 .man-image-1 {
    top: -12px;
    left: 110px;
    width: 175px;
  }
  .features-section .feature-item:nth-child(2) .feature-image-7 .man-image-2 {
    left: 0px;
    bottom: 30px;
    width: 120px;
  }
  .features-section .feature-item:nth-child(2) .feature-image-7 .man-image-3 {
    right: 0px;
    bottom: 30px;
    width: 235px;
  }
  .features-section .feature-item:nth-child(2) .feature-image-7 .leaf-image {
    right: 0px;
    bottom: 30px;
    width: 140px;
    z-index: 1;
  }

  .software-section {
    .software-container {
      .software-item {
        display: table;
        margin: 30px auto 0px;
        &:nth-child(1),
        &:nth-child(2),
        &:nth-child(3),
        &:nth-child(4),
        &:nth-child(5),
        &:nth-child(6),
        &:nth-child(7) {
          top: unset;
          left: unset;
          right: unset;
          bottom: unset;
          transform: unset;
          position: static;
          width: 130px;
          height: 130px;
          .software-logo {
            padding: 35px;
          }
        }
      }
    }
  }

  .free-trail-section .free-trail-form {
    padding: 15px;
  }
  .free-trail-section .free-trail-form .form-item .form-item-btn {
    width: 100%;
    display: block;
    position: static;
    transform: unset;
    padding: 0px 40px;
    margin-top: 15px;
  }
  /* home 7 pages - end
	================================================== */

  /* home 8 pages - start
	================================================== */
  .banner-section .sass-banner-2 {
    padding-top: 130px;
    padding-bottom: 65px;
  }
  .banner-section .sass-banner-2 .banner-content .title-text {
    font-size: 24px;
    line-height: 36px;
    margin-bottom: 20px;
  }
  .banner-section .sass-banner-2 .banner-content {
    padding-top: 0px;
  }
  .banner-section
    .sass-banner-2
    .banner-content
    .newsletter-form
    .form-item
    .submit-btn {
    height: 40px;
    padding: 0px 20px;
    line-height: 40px;
  }
  .banner-section
    .sass-banner-2
    .banner-content
    .newsletter-form
    .form-item
    input {
    height: 50px;
  }

  .home-page-8 .features-section .feature-item:nth-child(1) {
    margin-bottom: 100px;
  }
  .features-section .feature-item:nth-child(1) .feature-image-2 {
    margin-top: 0px;
    margin-bottom: 60px;
  }
  .features-section .feature-item .feature-image-8 .image-2 {
    left: 20px;
    top: -50px;
    width: 150px;
  }
  .features-section .feature-item .feature-image-8 .image-3 {
    right: 0px;
    width: 150px;
    bottom: -50px;
  }
  .features-section .feature-item:nth-child(3) .feature-image-8 .image-2 {
    top: 0px;
    left: 100px;
  }
  .features-section .feature-item:nth-child(3) .feature-image-8 .image-3 {
    right: 0px;
    bottom: 0px;
  }

  .software-section .software-container .software-item:nth-child(1),
  .software-section .software-container .software-item:nth-child(2),
  .software-section .software-container .software-item:nth-child(3),
  .software-section .software-container .software-item:nth-child(4),
  .software-section .software-container .software-item:nth-child(5),
  .software-section .software-container .software-item:nth-child(6),
  .software-section .software-container .software-item:nth-child(7) {
    width: 100px;
    height: 100px;
    .software-logo {
      padding: 25px;
    }
  }
  /* home 8 pages - end
	================================================== */

  /* home 9 pages - start
	================================================== */
  .banner-section .sass-banner-3 .banner-content .title-text {
    font-size: 24px;
    line-height: 36px;
  }
  .banner-section .sass-banner-3 .banner-content p {
    font-size: 14px;
    line-height: 26px;
    margin-bottom: 30px;
  }
  .banner-section .sass-banner-3 .banner-content .btns-group > ul > li {
    width: 100%;
    display: table;
    margin-right: 0px;
    text-align: center;
    margin-bottom: 10px;
    &:last-child {
      margin-bottom: 0px;
    }
  }
  .banner-section
    .sass-banner-3
    .banner-content
    .btns-group
    > ul
    > li
    .info-text {
    width: 100%;
    display: table;
    font-size: 14px;
  }
  .banner-section .sass-banner-3 {
    padding-top: 160px;
  }

  .features-section .feature-item .feature-content .feature-item-title {
    font-size: 24px;
    margin-bottom: 20px;
  }
  .features-section
    .feature-item
    .feature-content
    .service-list
    > ul
    > li
    .item-icon {
    float: none;
    display: table;
    margin: 0px auto 30px;
  }
  .features-section
    .feature-item
    .feature-content
    .service-list
    > ul
    > li
    .item-content {
    width: 100%;
    display: table;
    text-align: center;
  }
  /* home 9 pages - end
	================================================== */

  /* home 10 pages - start
	================================================== */
  .banner-section .sass-banner-4 {
    padding-top: 170px;
    padding-bottom: 60px;
  }
  .banner-section .sass-banner-4 .banner-content .title-text {
    font-size: 24px;
    line-height: 36px;
  }
  .banner-section .sass-banner-4 {
    .shape-1,
    .shape-2,
    .shape-3,
    .shape-4,
    .shape-5,
    .shape-6 {
      display: none;
    }
  }
  .banner-section .sass-banner-4 .banner-content p {
    font-size: 14px;
    line-height: 26px;
    margin-bottom: 40px;
  }
  .banner-section .sass-banner-4 .banner-content .btns-group > ul > li {
    width: 100%;
    display: table;
    margin-right: 0px;
    text-align: center;
    margin-bottom: 15px;
    &:last-child {
      margin-bottom: 0px;
    }
  }
  .banner-section
    .sass-banner-4
    .banner-content
    .btns-group
    > ul
    > li
    .info-text {
    width: 100%;
    padding: 0px;
    display: table;
    font-size: 14px;
    font-weight: 500;
    text-align: center;
  }

  .client-section .title-paragraph-text {
    font-size: 16px;
    max-width: 100%;
    line-height: 28px;
    margin: 0 auto 50px;
  }

  .features-section .feature-item .feature-image-10 .big-image {
    width: 100%;
  }
  .features-section .feature-item .feature-image-10 .small-image-1 {
    top: 45px;
    left: 0px;
    width: 160px;
  }
  .features-section .feature-item .feature-image-10 .small-image-2 {
    right: 0px;
    bottom: -30px;
    width: 160px;
  }
  .features-section .feature-item .feature-image-10 {
    margin-bottom: 60px;
  }
  .features-section .feature-item .feature-image-10 .shape-1 {
    top: -20px;
    left: 40px;
    width: 50px;
  }
  .features-section .feature-item .feature-image-10 .shape-2 {
    left: 90px;
    bottom: 0px;
    width: 50px;
  }
  .features-section .feature-item:nth-child(2) .feature-image-10 .big-image {
    margin-left: 0px;
    margin-right: 0px;
  }
  .features-section
    .feature-item:nth-child(2)
    .feature-image-10
    .small-image-1 {
    top: 35px;
    left: 25px;
  }
  /* home 10 pages - end
	================================================== */

  /* home 11 pages - start
	================================================== */
  .slider-section .appstore-main-carousel .item .mobile-banner-image > img {
    max-width: 250px;
  }
  .slider-section .appstore-main-carousel {
    padding-top: 135px;
  }
  .slider-section .appstore-main-carousel .item .item-content h1 {
    font-size: 24px;
    line-height: 36px;
  }
  .slider-section .appstore-main-carousel .item .item-content p {
    font-size: 14px;
    max-width: 100%;
    line-height: 26px;
    margin-bottom: 30px;
  }
  .slider-section .appstore-main-carousel .item .item-content .btns-group > ul {
    width: 100%;
  }
  .slider-section
    .appstore-main-carousel
    .item
    .item-content
    .btns-group
    > ul
    > li {
    width: 100%;
    display: table;
    margin-right: 0px;
    text-align: center;
    margin-bottom: 5px;
    &:last-child {
      margin-bottom: 0px;
    }
  }
  .mobile-app-section .section-title .custom-btn-underline {
    display: table;
    margin: 30px auto 0px;
    float: none !important;
  }
  .mobile-app-section .section-title .title-text {
    width: 100%;
    display: table;
    font-size: 30px;
    text-align: center;
    float: none !important;
  }
  .mobile-app-section .app-item {
    width: 230px;
    display: table;
    margin: 30px auto 0px;
  }
  .app-Category-section .Category-item {
    text-align: center;
  }
  .app-Category-section .Category-item > ul > li > a {
    font-size: 14px;
    font-weight: 400;
  }
  /* home 11 pages - end
	================================================== */

  /* home 12 pages - start
	================================================== */
  .search-section .banner-content h1 {
    font-size: 22px;
    line-height: 30px;
    margin-bottom: 30px;
  }
  .search-section .banner-content .search-form .form-item input {
    height: 50px;
    padding: 0px 30px;
  }
  .search-section .banner-content .search-form .form-item .search-btn {
    right: 30px;
    font-size: 18px;
    transform: translateY(-50%);
  }
  .mobile-app-section .tab-content .tab-pane .app-item {
    width: 230px;
    display: table;
    margin: 30px auto 0px;
  }
  /* home 12 pages - end
	================================================== */

  /* app details 1 pages - start
	================================================== */
  .app-details-section .app-details-content .app-item {
    padding: 30px;
  }
  .app-details-section .app-details-content .app-item .app-image {
    width: 100%;
    height: auto;
    text-align: center;
    float: none !important;
  }
  .app-details-section .app-details-content .app-item .rating-star {
    display: table;
    position: static;
    margin: 15px auto 0px;
  }
  .app-details-section .app-details-content .app-item .app-content {
    width: 100%;
    padding: 0px;
    display: table;
    margin-top: 30px;
    text-align: center;
  }
  .app-details-section
    .app-details-content
    .app-item
    .app-content
    .item-tag
    > ul {
    display: table;
    margin: 0px auto;
  }
  .app-details-section .app-details-content .app-item .btns-group {
    margin-top: 30px;
  }
  .app-details-section .app-details-content .app-item .btns-group > ul > li {
    width: 100%;
    display: table;
    margin-right: 0px;
    text-align: center;
    margin-bottom: 15px;
  }
  .app-details-section
    .app-details-content
    .overall-review
    .overall-review-nav {
    width: 100%;
    overflow-x: scroll;
    margin-bottom: 40px;
  }
  .app-details-section
    .app-details-content
    .overall-review
    .overall-review-nav
    > ul {
    width: 450px;
  }
  .app-details-section
    .app-details-content
    .overall-review
    .overall-review-nav
    > ul
    > li {
    margin-right: 30px;
    &:last-child {
      margin-right: 0px;
    }
  }
  .app-details-section
    .app-details-content
    .overall-review
    .overall-review-nav
    > ul
    > li
    > a {
    font-size: 18px;
    padding-bottom: 15px;
  }

  .app-details-section .app-details-content .overall-review {
    padding: 30px;
  }
  .app-details-section .app-details-content .overall-review .write-review-btn {
    margin-bottom: 40px;
    text-align: center !important;
  }
  .app-details-section
    .app-details-content
    .overall-review
    .tab-content
    .pane-item-title {
    text-align: center;
  }
  .sidebar-section.app-details-sidebar .widget_search .form-item .search-btn {
    height: 45px;
    font-size: 16px;
    line-height: 45px;
  }
  .app-details-section
    .app-details-content
    .overall-review
    .tab-content
    #review-pane
    .item-ratings
    > ul
    > li
    .single-experties {
    width: 100%;
    margin-right: 0px;
  }
  /* app details 1 pages - end
	================================================== */

  /* about page - start
	================================================== */
  .about-section .about-image .popup-video {
    right: 20px;
    width: 80px;
    height: 80px;
    bottom: 30px;
    line-height: 80px;
  }
  .about-section .about-content .title-text {
    font-size: 24px;
    line-height: 36px;
    margin-bottom: 30px;
  }
  .service-section .section-title .paragraph-text {
    font-size: 18px;
    line-height: 30px;
  }
  .funfact-section .section-title .title-text {
    width: 100%;
    line-height: 42px;
    font-size: 30px;
  }
  .funfact-section .bg-image {
    background-size: 100% 100%;
  }
  .funfact-section .counter-items-list > ul > li {
    margin: 0px;
    width: 100%;
    display: table;
    margin-top: 40px;
    text-align: center;
  }
  .testimonial-section .testimonial-carousel .item.item-style-2 .hero-image {
    width: 100%;
  }
  .testimonial-section .testimonial-carousel .item .hero-image .icon {
    width: 60px;
    height: 60px;
    font-size: 24px;
    line-height: 64px;
  }
  .testimonial-section
    .testimonial-carousel
    .item.item-style-2
    .hero-image
    .shape-image {
    bottom: 0px;
  }
  .testimonial-section .testimonial-carousel .item .testimonial-content {
    width: 100%;
  }
  .testimonial-section
    .testimonial-carousel
    .item
    .testimonial-content
    .paragraph-text {
    font-size: 14px;
    line-height: 26px;
  }
  /* about page - end
	================================================== */

  /* pricing pages - start
	================================================== */
  .pricing-section .bg-image .pricing-plan {
    max-width: 100%;
  }
  .pricing-section .pricing-plan {
    max-width: 100%;
  }
  /* pricing pages - end
	================================================== */

  /* testimonial pages - start
	================================================== */
  .testimonial-section .review-item .reviewer-image {
    width: 130px;
    display: table;
    position: static;
    transform: unset;
    margin: 0px auto 30px;
  }
  .testimonial-section .review-item .reviewer-content {
    width: 100%;
    padding: 0px;
    text-align: center;
  }
  .testimonial-section .review-item .reviewer-content .reviewer-comment {
    font-size: 14px;
    line-height: 24px;
    margin-bottom: 20px;
  }
  .testimonial-section .review-item .reviewer-content .rating-star > ul {
    float: none;
    display: table;
    margin: 0 auto;
  }
  .testimonial-section .review-item .reviewer-content .reviewer-info {
    margin-bottom: 20px;
  }
  /* testimonial pages - end
	================================================== */

  /* team pages - start
	================================================== */
  .team-section
    .team-member-grid
    .member-image
    .member-social-links
    > li:nth-child(2) {
    left: 125px;
  }
  .team-section
    .team-member-grid
    .member-image
    .member-social-links
    > li:nth-child(3) {
    right: 125px;
  }
  /* team pages - end
	================================================== */

  /* faq pages - start
	================================================== */
  .faq-section .faq-tabs-nav > .nav {
    margin-bottom: 50px;
  }
  .faq-section .faq-tabs-nav {
    padding: 30px;
    margin-bottom: 30px;
  }
  .faq-section .faq-tabs-nav > .nav > .nav-item > .nav-link:before {
    left: -30px;
  }
  .faq-section .contact-info > p {
    width: 100%;
  }
  /* faq pages - end
	================================================== */

  /* blog pages - start
	================================================== */
  .blog-section .big-blog-item .blog-content {
    padding: 25px;
  }
  .blog-section .big-blog-item .blog-content .blog-title .title-link {
    font-size: 20px;
    line-height: 30px;
  }
  .blog-section .big-blog-item .blog-content .item-paragraph {
    font-size: 14px;
  }
  .blog-section .big-blog-item .blog-image .post-date {
    left: 25px;
    bottom: 25px;
  }
  .blog-section .big-blog-item .blog-content .item-paragraph {
    margin-bottom: 20px;
  }
  /* blog pages - end
	================================================== */

  /* blog details pages - start
	================================================== */
  .blog-details-page-1 .blog-details-section .blog-details-content {
    margin-top: -90px;
    padding: 40px 20px;
  }
  .blog-details-section .blog-details-content .blog-title {
    font-size: 22px;
    line-height: 30px;
  }
  .blog-details-page-1 .breadcrumb-section .overlay-black {
    height: 215px;
  }
  .blog-details-section .blog-details-content .title-text {
    font-size: 18px;
  }
  .blog-details-section .blog-details-content .blockquote {
    padding: 30px 25px;
    border-left: 3px solid #138afd;
  }
  .blog-details-section .blog-details-content .blockquote > p {
    font-size: 14px;
    line-height: 24px;
  }
  .blog-details-section .blog-details-content .features-list > ul {
    width: 100%;
  }
  .blog-details-section .blog-details-content .post-admin {
    padding: 30px;
  }
  .blog-details-section .blog-details-content .post-admin .admin-content > p {
    font-size: 14px;
    line-height: 24px;
    margin-bottom: 30px;
  }
  .blog-details-section
    .blog-details-content
    .peoples-comments
    .comment-item
    .hero-image {
    float: none;
    margin: 0px;
    display: table;
    margin-bottom: 30px;
  }
  .blog-details-section
    .blog-details-content
    .peoples-comments
    .comment-item
    .comment-content {
    width: 100%;
  }
  /* blog details pages - end
	================================================== */

  /* 404 error pages - start
	================================================== */
  .common-page .brand-logo,
  .common-page .social-links {
    display: none;
  }
  .error-section .common-container .item-content {
    width: 100%;
  }
  .common-page .common-container .shape-1,
  .common-page .common-container .shape-2,
  .common-page .common-container .shape-3,
  .common-page .common-container .shape-4,
  .common-page .common-container .shape-5,
  .common-page .common-container .shape-6,
  .common-page .common-container .shape-7,
  .common-page .common-container .shape-8,
  .common-page .common-container .shape-9 {
    display: none;
  }
  .common-page .common-container .item-content .title-text {
    font-size: 42px;
  }
  .common-page .common-container .item-content > p {
    font-size: 16px;
    line-height: 28px;
  }
  /* 404 error pages - end
	================================================== */

  /* coming soon pages - start
	================================================== */
  .coming-soon-section .common-container .item-content .email-form .form-item {
    width: 100%;
  }
  .common-page .common-container .item-content .title-text {
    font-size: 36px;
  }
  .common-page .common-container .item-content > p {
    font-size: 14px;
    line-height: 26px;
  }
  .coming-soon-section {
    .common-container {
      .item-content {
        .countdown-timer {
          ul {
            li {
              margin-right: 20px;
              padding-right: 20px;
              &:before {
                top: 5px;
                right: -4px;
                font-size: 30px;
              }
              &:last-child {
                margin-right: 0px;
                padding-right: 0px;
              }
              strong {
                font-size: 36px;
              }
              small {
                letter-spacing: 0px;
              }
            }
          }
        }
      }
    }
  }
  .coming-soon-section
    .common-container
    .item-content
    .email-form
    .form-item
    .submit-btn {
    height: 35px;
    padding: 0px 20px;
    line-height: 35px;
  }
  .coming-soon-section
    .common-container
    .item-content
    .email-form
    .form-item
    input {
    height: 45px;
  }
  .form-item input {
    height: 45px;
  }
  /* coming soon pages - end
	================================================== */

  /* register pages (login) - start
	================================================== */
  .common-page .common-container .item-content .title-text {
    font-size: 30px;
    margin-bottom: 20px;
  }
  .common-page .common-container .item-content > p {
    margin-bottom: 30px;
  }
  .register-section
    .common-container
    .item-content
    .register-form
    .forgot-password {
    margin-bottom: 30px;
  }
  /* register pages (login) - end
	================================================== */
}

/* ====================================================================================================
6 - media screen and (max-width: 480px) - end
==================================================================================================== */

/* ====================================================================================================
7 - media screen and (max-width: 320px) - start
==================================================================================================== */

@media screen and (max-width: 320px) {
  /* global reset - start
	================================================== */
  .section-title .title-text {
    font-size: 22px;
  }

  .post-meta > ul > li > .post-admin .admin-image {
    width: 35px;
    height: 35px;
    margin: -10px 0px;
    margin-right: 5px;
  }
  .post-meta > ul > li {
    margin-right: 20px;
  }
  /* global reset - start
	================================================== */

  /* footer section - start
	================================================== */
  .footer-section .newsletter-section .section-title .title-text {
    font-size: 24px;
    max-width: 100%;
  }
  /* footer section - start
	================================================== */

  /* breadcrumb section - start
	================================================== */
  .breadcrumb-section .section-title .title-text {
    font-size: 20px;
  }
  /* breadcrumb section - start
	================================================== */

  /* breadcrumb section - start
	================================================== */
  .sidebar-section .widget_instagram > ul > li {
    width: 79px;
    height: 79px;
  }
  /* breadcrumb section - start
	================================================== */

  /* home 1 pages - start
	================================================== */
  .banner-section .mobile-app-banner-1 .banner-content > h2 {
    font-size: 23px;
    line-height: 30px;
  }
  .features-section .feature-item .feature-image-1 .phone-image {
    left: 34px;
  }
  .features-section .feature-item .feature-image-1 .comment-image-1 {
    left: 65px;
  }
  .features-section .feature-item .feature-image-1 .comment-image-2 {
    left: 80px;
  }
  .features-section .feature-item:nth-child(2) .feature-image-1 .phone-image {
    top: 0px;
    right: 35px;
  }
  /* home 1 pages - end
	================================================== */

  /* home 2 pages - start
	================================================== */
  .banner-section .mobile-app-banner-2 .mobile-banner-image > img {
    display: table;
    max-width: 270px;
    margin: 0px auto;
  }
  .banner-section .mobile-app-banner-2 .banner-content > h2 {
    font-size: 23px;
    line-height: 32px;
  }
  .features-section .feature-item .feature-content .feature-item-title {
    font-size: 24px;
    margin-top: 30px;
    margin-bottom: 30px;
  }
  /* home 2 pages - end
	================================================== */

  /* home 3 pages - start
	================================================== */
  .slider-section .mobileapp-main-carousel .item.first-item h2 {
    font-size: 16px;
    line-height: 26px;
  }
  .slider-section
    .mobileapp-main-carousel
    .item.second-item
    .slider-content
    .title-text {
    font-size: 20px;
  }
  .features-section .feature-item .feature-image-3 {
    margin-top: 60px;
    padding-top: 50px;
  }
  .features-section .feature-item .feature-image-3 .bg-image {
    width: 250px;
  }
  .features-section .feature-item .feature-image-3 .phone-image {
    width: 150px;
    top: 0px;
  }
  .features-section .feature-item .feature-image-3 .man-image-1 {
    left: 35px;
    width: 65px;
  }
  .features-section .feature-item .feature-image-3 .man-image-2 {
    right: 15px;
    width: 90px;
  }
  .features-section .feature-item .feature-image-3 .comment-image {
    width: 60px;
    top: 55px;
    left: 115px;
  }
  .features-section .feature-item .feature-image-3 .leaf-image-1 {
    left: 30px;
    top: -50px;
    width: 100px;
  }
  .features-section .feature-item .feature-image-3 .leaf-image-2 {
    top: -30px;
    right: 20px;
    width: 100px;
  }
  .features-section .feature-item:nth-child(2) .feature-image-3 {
    margin-bottom: 60px;
    padding-top: 50px;
  }
  .features-section .feature-item:nth-child(2) .feature-image-3 .bg-image {
    width: 250px;
  }
  .features-section .feature-item:nth-child(2) .feature-image-3 .phone-image {
    left: 95px;
    top: 55px;
    width: 115px;
  }
  .features-section .feature-item:nth-child(2) .feature-image-3 .man-image-1 {
    left: 25px;
    width: 100px;
    bottom: -15px;
  }
  .features-section .feature-item:nth-child(2) .feature-image-3 .man-image-2 {
    right: 10px;
    width: 95px;
  }
  .features-section .feature-item:nth-child(2) .feature-image-3 .leaf-image-1 {
    top: 10px;
    left: 40px;
    width: 120px;
  }
  .features-section .feature-item:nth-child(2) .feature-image-3 .leaf-image-2 {
    top: 20px;
    right: 20px;
    width: 100px;
  }
  /* home 3 pages - end
	================================================== */

  /* home 4 pages - start
	================================================== */
  .banner-section .mobile-app-banner-4 .banner-content > h2 {
    font-size: 20px;
    line-height: 28px;
  }
  .features-section .feature-item .feature-image-4 .phone-image {
    width: 110px;
  }
  .features-section .feature-item .feature-image-4 .floor-image {
    bottom: 24px;
    width: 200px;
    left: 40px;
  }
  .features-section .feature-item .feature-image-4 .man-image-1 {
    bottom: 40px;
    left: 35px;
    width: 60px;
  }
  .features-section .feature-item .feature-image-4 .flower-image-2 {
    width: 25px;
    bottom: 40px;
    right: 60px;
  }
  .features-section .feature-item .feature-image-4 .comment-1-image {
    top: 40px;
    left: 50px;
    width: 120px;
  }
  .features-section .feature-item .feature-image-4 .comment-2-image {
    top: 105px;
    left: 82px;
    width: 120px;
  }
  .features-section .feature-item .feature-image-4 {
    margin-bottom: 40px;
  }
  .features-section .feature-item:nth-child(2) .feature-image-4 .box-image-1 {
    top: 30px;
    left: 110px;
    width: 30px;
  }
  .features-section .feature-item:nth-child(2) .feature-image-4 .box-image-2 {
    top: 70px;
    right: 110px;
    width: 30px;
  }
  .features-section .feature-item:nth-child(2) .feature-image-4 .box-image-3 {
    top: 120px;
    left: 110px;
    width: 30px;
  }
  .features-section .feature-item:nth-child(2) .feature-image-4 .man-image-2 {
    bottom: 0px;
    left: 0px;
    width: 150px;
  }
  .features-section .feature-item:nth-child(2) .feature-image-4 .man-image-1 {
    bottom: 0px;
    right: 75px;
    width: 70px;
  }
  .features-section .feature-item:nth-child(2) .feature-image-4 .flower-image {
    width: 40px;
    right: 50px;
    bottom: 35px;
  }
  /* home 4 pages - end
	================================================== */

  /* home 5 pages - start
	================================================== */
  .banner-section .mobile-app-banner-5 .mobile-banner-image {
    > img {
      max-width: 250px;
    }
  }
  /* home 5 pages - end
	================================================== */

  /* home 6 pages - start
	================================================== */
  .banner-section .mobile-app-banner-6 .mobile-banner-image {
    > img {
      max-width: 100%;
    }
  }
  .banner-section .mobile-app-banner-6 .banner-content h2 {
    font-size: 18px;
  }

  .service-section .service-grid-item {
    padding: 40px 20px;
  }
  .service-section .service-grid-item p {
    font-size: 14px;
  }
  /* home 6 pages - end
	================================================== */

  /* home 7 pages - start
	================================================== */
  .banner-section .sass-banner-1 .banner-content .title-text {
    font-size: 20px;
  }
  .banner-section
    .sass-banner-1
    .banner-content
    .newsletter-form
    .form-item
    .submit-btn {
    height: 40px;
    padding: 0px 20px;
    line-height: 40px;
  }
  .banner-section
    .sass-banner-1
    .banner-content
    .newsletter-form
    .form-item
    input {
    height: 50px;
  }
  .banner-section .sass-banner-1 .banner-content .info-list > ul > li {
    width: 100%;
    margin-right: 0px;
    text-align: center;
    margin-bottom: 5px;
    &:last-child {
      margin-bottom: 0px;
    }
  }

  .features-section .feature-item .feature-image-7 .man-image-1 {
    left: 30px;
    width: 80px;
    bottom: 65px;
  }
  .features-section .feature-item .feature-image-7 .man-image-2 {
    left: -30px;
    bottom: 65px;
    width: 100px;
  }
  .features-section .feature-item .feature-image-7 .man-image-3 {
    bottom: 60px;
    width: 80px;
    right: 0px;
  }
  .features-section .feature-item .feature-image-7 .leaf-image {
    width: 40px;
    bottom: 60px;
    right: -20px;
  }

  .features-section .feature-item:nth-child(2) .feature-image-7 .man-image-1 {
    top: -17px;
    left: 95px;
    width: 175px;
  }
  .features-section .feature-item:nth-child(2) .feature-image-7 .man-image-2 {
    left: 0px;
    bottom: 30px;
    width: 100px;
  }
  .features-section .feature-item:nth-child(2) .feature-image-7 .man-image-3 {
    right: 0px;
    bottom: 30px;
    width: 200px;
  }
  .features-section .feature-item:nth-child(2) .feature-image-7 .leaf-image {
    right: 0px;
    bottom: 30px;
    width: 90px;
    z-index: 1;
  }
  /* home 7 pages - end
	================================================== */

  /* home 8 pages - start
	================================================== */
  .banner-section .sass-banner-2 .banner-content .title-text {
    font-size: 20px;
    line-height: 30px;
  }
  .banner-section .sass-banner-2 {
    padding-top: 160px;
  }
  .features-section .feature-item:nth-child(1) .feature-image-2 {
    margin-bottom: 25px;
  }
  .features-section
    .feature-item
    .feature-content
    .service-list
    > ul
    > li
    .item-icon {
    float: none;
    display: table;
    margin: 0px auto 30px;
  }
  .features-section
    .feature-item
    .feature-content
    .service-list
    > ul
    > li
    .item-content {
    width: 100%;
    display: table;
    text-align: center;
  }
  /* home 8 pages - end
	================================================== */

  /* home 9 pages - start
	================================================== */
  .banner-section .sass-banner-3 .banner-content .title-text {
    font-size: 20px;
    line-height: 30px;
  }
  /* home 9 pages - end
	================================================== */

  /* home 11 pages - start
	================================================== */
  .slider-section .appstore-main-carousel .item .mobile-banner-image > img {
    max-width: 200px;
  }
  /* home 11 pages - end
	================================================== */

  /* home 12 pages - start
	================================================== */
  .search-section .banner-content h1 {
    font-size: 20px;
  }
  /* home 12 pages - end
	================================================== */

  /* about page - start
	================================================== */
  .about-section .about-content .title-text {
    font-size: 20px;
    line-height: 30px;
  }
  .funfact-section .section-title .title-text {
    font-size: 24px;
    line-height: 36px;
  }
  .testimonial-section
    .testimonial-carousel
    .item.item-style-2
    .hero-image
    .shape-image {
    display: none;
  }
  /* about page - end
	================================================== */

  /* team pages - start
	================================================== */
  .team-section
    .team-member-grid
    .member-image
    .member-social-links
    > li:nth-child(2) {
    left: 115px;
  }
  .team-section
    .team-member-grid
    .member-image
    .member-social-links
    > li:nth-child(3) {
    right: 115px;
  }
  /* team pages - end
	================================================== */

  /* contact pages - start
	================================================== */
  .contact-section .contact-info-item {
    width: 100%;
    margin: 30px auto 0px;
  }
  .contact-section .contact-info-item > ul > li {
    font-size: 16px;
    margin-bottom: 5px;
  }
  /* contact pages - end
	================================================== */

  /* blog pages - start
	================================================== */
  .blog-section .big-blog-item .blog-content .blog-title .title-link,
  .blog-section .blog-grid-item .blog-content .blog-title .title-link {
    font-size: 16px;
    line-height: 26px;
  }
  .blog-section .big-blog-item .blog-content .blog-title {
    margin-bottom: 15px;
  }
  .blog-section .big-blog-item .blog-content {
    padding: 20px;
  }
  /* blog pages - end
	================================================== */

  /* blog details pages - start
	================================================== */
  .blog-details-page-1 .blog-details-section .blog-details-content {
    padding: 0px;
    margin-top: 30px;
  }
  .blog-details-page-1 .breadcrumb-section .overlay-black {
    height: 170px;
  }
  /* blog details pages - end
	================================================== */

  /* 404 error pages - start
	================================================== */
  .common-page .common-container .item-content > p {
    font-size: 14px;
    line-height: 26px;
    margin-bottom: 30px;
  }
  /* 404 error pages - end
	================================================== */

  /* coming soon pages - start
	================================================== */
  .coming-soon-section {
    .common-container {
      .item-content {
        .countdown-timer {
          ul {
            li {
              margin-right: 15px;
              padding-right: 15px;
              &:before {
                top: 3px;
                right: -4px;
                font-size: 30px;
              }
              &:last-child {
                margin-right: 0px;
                padding-right: 0px;
              }
              strong {
                font-size: 30px;
              }
              small {
                letter-spacing: 0px;
              }
            }
          }
        }
      }
    }
  }
  .common-page .common-container .item-content .title-text {
    font-size: 30px;
  }
  /* coming soon pages - end
	================================================== */

  /* register pages (register) - start
	================================================== */
  .common-page .common-container .item-content .title-text {
    margin-bottom: 0px;
  }
  .common-page .common-container .item-content > p {
    font-size: 12px;
    line-height: 24px;
    margin-bottom: 15px;
  }
  .register-section
    .common-container
    .item-content
    .register-form
    .tearm-condition
    label {
    font-size: 12px;
  }
  .register-section
    .common-container
    .item-content
    .register-form
    .tearm-condition {
    margin-bottom: 20px;
  }
  /* register pages (register) - end
	================================================== */
}

/* ====================================================================================================
7 - media screen and (max-width: 320px) - end
==================================================================================================== */
